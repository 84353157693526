/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
  IThemeConfig,
  useTheme,
  getConfig,
} from"../../core/index";
import { StartDashboardPage } from "./StartDashboardPage";
import axios from "axios";
import log from '../../logger';
import { encrypt } from "../../crypto-encryption/cryptoUtils";

const defaultPageConfig = getConfig();
const dashboardPageConfig: Partial<IThemeConfig> = {
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: false,
  },
};

export function StartDashboardWrapper() {
  const location = useLocation();
  // const navigate = useNavigate();

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const paramValue = queryParams.get('param');
  const { setTheme } = useTheme();
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(dashboardPageConfig);
    const fetchProfileData = async () => {
      try {
        const username = 'mfb_apikey';
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
          log.error("API key not found in localStorage");
          return;
        }
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/profile`,
          { headers: headers }
        );
        console.log(response.data.object.data[0]);
        localStorage.setItem("organizationId",response.data.object.data[0].organization_id);
        localStorage.setItem("firstName",response.data.object.data[0].first_name);
        localStorage.setItem("lastName",response.data.object.data[0].last_name);
        localStorage.setItem("organizationName",response.data.object.data[0].org_name);
        localStorage.setItem("languageCode",response.data.object.data[0].org_languageCode);
        localStorage.setItem("currencyCode",response.data.object.data[0].org_currencyCode);
        if(response.data.object.data[0].org_currencyCode === "USD") {
          localStorage.setItem("currencySymbol", "$");
        } else if(response.data.object.data[0].org_currencyCode === "EUR") {
          localStorage.setItem("currencySymbol", "€");
        }
        if(response.data.object.data[0].org_languageCode==="DE") {
          localStorage.setItem("selectedLang", "de");
        }else if(response.data.object.data[0].org_languageCode==="FR") {
          localStorage.setItem("selectedLang", "fr");
        } else if(response.data.object.data[0].org_languageCode==="EN") {
          localStorage.setItem("selectedLang", "en");
        } else {
          
        }
        log.info('User Logged in - ',response.data.object.data[0].organization_id);
      } catch (error) {
        log.error("Error fetching profile details:", error);
      }
    };
    if(paramValue) {
      localStorage.setItem("apiKey",paramValue.replace(/\s/g, "+"));
      localStorage.setItem("encryptedApiKey",encrypt(paramValue.replace(/\s/g, "+")));
      console.log("param", paramValue.replace(/\s/g, "+"));
      console.log("Api key in LS:",localStorage.getItem("apiKey"));
      
      fetchProfileData();
      window.history.replaceState({}, document.title, window.location.pathname);
      setTimeout(() => {
        window.location.reload();
      }, 2000);    
      
    }

    if(!paramValue && !localStorage.getItem("apiKey")) {
      window.location.href = "https://fcrmrecurring.myfundbox.com/login.jsf";
    }
    
    return () => {
      setTheme(defaultPageConfig);
    };
  }, [paramValue]);

  return <StartDashboardPage />;
}
