// import { BlobServiceClient, BlobSASPermissions } from '@azure/storage-blob';
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuid } from 'uuid';

export class AzureStorageService {
  private sasUrl = process.env.REACT_APP_AZURE_SAS_URL;
  private blobServiceClient: BlobServiceClient = new BlobServiceClient(this.sasUrl);
  private containerClient = this.blobServiceClient.getContainerClient("cover");

  async uploadCoverImage(file: File, id: number): Promise<void> {
    console.log("uploadCoverImage called with id", id);
    const blobName = `${id}`;
    console.log("Blob name setted", id);
    const containerClient = this.blobServiceClient.getContainerClient("cover");
    console.log("Container client getted", id);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    console.log("blockBlobClient getted", id);
    await blockBlobClient.uploadData(file);
    console.log("Uploaded cover image with id", id);
  }

  async uploadImageToDocuments(file: File, id: number): Promise<string> {
    const random = uuid();
    const blobName = `${id}/${random}`; // Construct blob name with directory and uuid
    // const tags = {
    //     id: `${id}`
    //   }
    const containerClient = this.blobServiceClient.getContainerClient("cover");
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    // blockBlobClient.setTags(tags);
    await blockBlobClient.uploadData(file);
    return `${process.env.REACT_APP_AZURE_URL}/cover/cover/${id}/${random}`;
  }

  async getBlobUrlsForId(id: number): Promise<string[]> {
    const containerClient = this.blobServiceClient.getContainerClient("cover");
    const blobUrls = [];
    for await (const blob of containerClient.listBlobsFlat({ prefix: `${id}/` })) {
      const blobUrl = await this.generateBlobUrl(blob.name);
      blobUrls.push(blobUrl);
    }
    return blobUrls;
  }

  private async generateBlobUrl(blobName: string): Promise<string> {
    const containerClient = this.blobServiceClient.getContainerClient("documents");
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const url = await blockBlobClient.generateSasUrl({
      permissions: { read: true, write: false, delete: false, add: false, create: false, deleteVersion: false, tag: false, move: false, execute: false, setImmutabilityPolicy: false, permanentDelete: false },
      startsOn: new Date(), // Default is the current time
      expiresOn: new Date(new Date().valueOf() + 86400), // Expires in 24 hours
    });
    return url;
  }

  async getBlobUrlsForIdTest(id: number) {
    // const containerClient = this.blobServiceClient.getContainerClient("documents");
    // let iter = containerClient.listBlobsByHierarchy("/", { prefix: `${id}/` });
    // let entity = await iter.next();
    // while (!entity.done) {
    //   let item = entity.value;
    //   console.log(`\tBlobPrefix: ${item.name}`);
    //   entity = await iter.next();
    // }
    // console.log(iter);
  }

  async getImage(blobName: string) {
    const containerClient = this.blobServiceClient.getContainerClient("cover");
    let i = 1;
    for await (const blob of containerClient.findBlobsByTags("id='7506'")) {
      console.log(`Blob ${i++}: ${blob.name}`);
    }
    // const blobsByTags = containerClient.findBlobsByTags(blobName);
    // let entity = await iter.next();
    // while (!entity.done) {
    //   let item = entity.value;
    //   console.log(`\tBlobPrefix: ${item.name}`);
    //   entity = await iter.next();
    // }
    // const pageBlobClient = containerClient.getPageBlobClient(blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    console.log(blockBlobClient.url);
    // let i = 0;
    // while(i<10){
    //   console.log(pageBlobClient.listPageRanges().next());
    //   i++;
    // }
    
    // const downloadResponse = await blockBlobClient.download();
    // return downloadResponse.blobBody;
  }
}

export default AzureStorageService;
