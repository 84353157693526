
import bg from "../../assets/images/HeroBg.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

type Props = {
  openModal: () => void;
};
const HeroSection: React.FC<Props> = ({ openModal }) => {
  const [t]=useTranslation("global")

  return (<>
    <section className="detail-hero position-relative">
        <img src={bg} alt="HeroBg"  />
    <div className="d-none d-md-block">
      <div className="card-layer p-4 col-8 d-flex justify-content-around align-items-center flex-wrap">
 
        <p className="layer-title">
        {t("layer-title1")} <br />
          {t("layer-title2")}
        </p>
        <button className="layer-btn d-flex align-items-center button-2"  onClick={openModal}>
        {t("lb")} <FontAwesomeIcon icon={faCircleRight} className="ms-3"  />
        </button>
      </div>
    </div>
  </section>
  <section className="fundrise-block d-md-none">
  <div className=" p-4  d-flex justify-content-around align-items-center flex-wrap">
    <p className="layer-title text-dark pb-3">
    {t("layer-title1")} <br />
          {t("layer-title2")}
    </p>
    <button className="layer-btn d-flex align-items-center" onClick={openModal} >
    {t("lb")} <FontAwesomeIcon icon={faCircleRight} className="ms-3"  />
   
    </button>
  </div>
</section>

    </>
  )
}

export default HeroSection