import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes} from '@fortawesome/free-solid-svg-icons';
import { ShareModal } from '../Modals/ShareModal';
import { useTranslation } from "react-i18next";
import dummyImg from "../../assets/images/f3.png";

type Props = {
    fundraiserData: any;
    id: any;
    encryptedId: any;
  }

const ProfileMain: React.FC<Props> = ({ fundraiserData, id, encryptedId }) => {

    const [t]=useTranslation("global")
    const [shows, setShows] = useState(false);
    const [imgSrc, setImgSrc] = useState(`${process.env.REACT_APP_AZURE_URL}/cover/cover/${id}`);

    return (

<>

        <div className="detail-shadow pb-3">
            <div className=" p-3 ">
                <div className="view-detail-img h-100">
                    <img src={imgSrc} onError={()=>setImgSrc(dummyImg)}
                        className="h-100" alt="detail-img" />
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 flex-wrap px-3 row">
                <h4 className="detail-title mb-0 text-start col-8 text-break h-100">
                    {/* {t("fct2")} */}
                    {fundraiserData.cause_name}
                </h4>
                <a
                    href="#staticBackdrop"
                    onClick={() => setShows(true)}
                    className="btn btn-dark  px-md-3 rounded-pill mt-3 mt-xl-0 col-4"
                   
                >
                    {t("sb")}
                    <span>
                    <FontAwesomeIcon icon={faShareNodes}  className="ps-2"/>
                    </span>
                </a>
            </div>
           
        </div>

      <ShareModal show={shows} handleClose={() => setShows(false)} id={encryptedId} />


        </>
    )
}

export default ProfileMain