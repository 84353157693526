import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
    show: boolean;
    handleClose: () => void;
};

const DeleteModal: React.FC<Props> = ({ show, handleClose }) => {
    const [t] = useTranslation("global")

    return (

        <>
            <Modal
                id="kt_modal_create_app"
                tabIndex={-1}
                aria-hidden="true"
                dialogClassName="modal-dialog-centered  modal-lg  del-modal"
                show={show}
                backdrop="static"
                onHide={handleClose}
            >
                <div className="container pt-10 del-bg br-35">
                    <div className="modal-header py-2 d-flex justify-content-end border-0">
                        {/* begin::Close */}
                        <h5 className="modal-title text-center d-none">{t("delete-title")}</h5>
                        <div className="d-flex justify-content-end border-0">

                            <div
                                className="btn btn-icon btn-sm btn-light-primary"
                                onClick={handleClose}
                            >
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" id="Rectangle-185" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" id="Rectangle-185-Copy" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body pb-0 ">
                        <div className="container-fluid ">
                            <div className="row align-items-center ">
                                <div className="col-md-6 ">
                                    <div className="success-content-right">
                                        <div className="success-img">
                                        <svg
  className="animated"
  id="freepik_stories-throw-down"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 500 500"
  version="1.1"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "svg#freepik_stories-throw-down:not(.animated) .animable {opacity: 0;}svg#freepik_stories-throw-down.animated #freepik--background-complete--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;animation-delay: 0s;}svg#freepik_stories-throw-down.animated #freepik--Plant--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideRight;animation-delay: 0s;}svg#freepik_stories-throw-down.animated #freepik--Character--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}svg#freepik_stories-throw-down.animated #freepik--Trash--inject-2 {animation: 3.0s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;animation-delay: 0s;}            @keyframes fadeIn {                0% {                    opacity: 0;                }                100% {                    opacity: 1;                }            }                    @keyframes slideRight {                0% {                    opacity: 0;                    transform: translateX(30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes slideLeft {                0% {                    opacity: 0;                    transform: translateX(-30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes slideUp {                0% {                    opacity: 0;                    transform: translateY(30px);                }                100% {                    opacity: 1;                    transform: inherit;                }            }        "
    }}
  />
  <g
    id="freepik--background-complete--inject-2"
    className="animable"
    style={{ transformOrigin: "238.585px 257.31px" }}
  >
    <polygon
      points="111.15 369.67 125.33 372 125.33 394.87 111.15 397.62 111.15 369.67"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "118.24px 383.645px"
      }}
      id="elq77g5lzxrqh"
      className="animable"
    />
    <rect
      x="124.83"
      y="369.26"
      width="3.6"
      height="27.95"
      rx="1.33"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "126.63px 383.235px"
      }}
      id="elufysx2ux1nm"
      className="animable"
    />
    <path
      d="M127.7,376.76h2a4,4,0,0,1,4,4v2.84a4,4,0,0,1-4,4h-2a0,0,0,0,1,0,0v-10.8A0,0,0,0,1,127.7,376.76Z"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "130.7px 382.18px"
      }}
      id="elscvul8xfcz"
      className="animable"
    />
    <path
      d="M124.72,395c-.19,0-.35-5.13-.35-11.45s.16-11.46.35-11.46.36,5.13.36,11.46S124.92,395,124.72,395Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "124.725px 383.545px"
      }}
      id="elym9saim6kbi"
      className="animable"
    />
    <path
      d="M128.24,395.12c-.2,0-.36-5.13-.36-11.46s.16-11.46.36-11.46.35,5.13.35,11.46S128.43,395.12,128.24,395.12Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "128.235px 383.66px"
      }}
      id="el82f84hq4jbj"
      className="animable"
    />
    <path
      d="M60.23,458.92V342.82a25.46,25.46,0,0,1,25.46-25.46h0a25.46,25.46,0,0,1,25.46,25.46v116.1Z"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "85.69px 388.14px"
      }}
      id="el00i0ak1bsxsok"
      className="animable"
    />
    <rect
      x="52.21"
      y="345.59"
      width="67.47"
      height="14.07"
      rx="5.19"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "85.945px 352.625px"
      }}
      id="el94xdwbba9id"
      className="animable"
    />
    <path
      d="M77.83,319.2l0-1a7.29,7.29,0,0,1,6.85-7.7l2-.1a7.3,7.3,0,0,1,7.6,7l.09,1.73Z"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "86.0939px 314.797px"
      }}
      id="elsn4ebuvou3"
      className="animable"
    />
    <path
      d="M85.05,307.56h2A1.44,1.44,0,0,1,88.45,309v2.64a0,0,0,0,1,0,0H83.62a0,0,0,0,1,0,0V309A1.44,1.44,0,0,1,85.05,307.56Z"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "86.035px 309.6px"
      }}
      id="el0qp14rgbv5k"
      className="animable"
    />
    <path
      d="M111.15,345.37c0,.2-11.38.36-25.41.36s-25.41-.16-25.41-.36S71.71,345,85.74,345,111.15,345.18,111.15,345.37Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "85.74px 345.365px"
      }}
      id="elvvkx341dhc8"
      className="animable"
    />
    <path
      d="M78.54,320.15c0,.1-1.46.71-3.75,2A25.66,25.66,0,0,0,67,328.39a22.55,22.55,0,0,0-4.18,9.09c-.54,2.53-.43,4.17-.55,4.17a3.35,3.35,0,0,1-.16-1.14,16.17,16.17,0,0,1,.22-3.13A21.09,21.09,0,0,1,66.42,328a23.43,23.43,0,0,1,8.14-6.29,26.63,26.63,0,0,1,2.87-1.21A3.06,3.06,0,0,1,78.54,320.15Z"
      style={{
        fill: "rgb(250, 250, 250)",
        transformOrigin: "70.3221px 330.9px"
      }}
      id="elxtnp3qq4jap"
      className="animable"
    />
    <path
      d="M94.3,318.67c-.07.25-3.68-1.2-8.32-1.28s-8.25,1.46-8.32,1.22a7.08,7.08,0,0,1,2.31-1,19.17,19.17,0,0,1,6-.91,20.75,20.75,0,0,1,6,1A7.22,7.22,0,0,1,94.3,318.67Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "85.98px 317.699px"
      }}
      id="elhau1aonwau"
      className="animable"
    />
    <path
      d="M89,310.83c-.08.19-1.26-.15-2.73-.18s-2.67.24-2.74.05,1.12-.8,2.75-.76S89,310.67,89,310.83Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "86.2635px 310.413px"
      }}
      id="el4aifrrj5jra"
      className="animable"
    />
    <path
      d="M103.74,386.7a17.59,17.59,0,1,1-17.59-17.59A17.59,17.59,0,0,1,103.74,386.7Z"
      style={{ fill: "rgb(224, 224, 224)", transformOrigin: "86.15px 386.7px" }}
      id="elbwdz84i6yfg"
      className="animable"
    />
    <path
      d="M103.74,383.65a17.59,17.59,0,1,1-17.59-17.6A17.59,17.59,0,0,1,103.74,383.65Z"
      style={{
        fill: "rgb(245, 245, 245)",
        transformOrigin: "86.15px 383.64px"
      }}
      id="elxshi48wmoe8"
      className="animable"
    />
    <path
      d="M103.74,383.65a32.48,32.48,0,0,0-.68-4.27,17.55,17.55,0,0,0-6.42-9.52,17.26,17.26,0,0,0-16.37-2.43,17.48,17.48,0,0,0-8,6,17.19,17.19,0,0,0,0,20.39,17.48,17.48,0,0,0,8,6,17.43,17.43,0,0,0,22.79-11.95,32.79,32.79,0,0,0,.68-4.26,6,6,0,0,1,0,1.12,8,8,0,0,1-.11,1.36,12.44,12.44,0,0,1-.33,1.84A17.49,17.49,0,0,1,97,397.83a17.77,17.77,0,0,1-16.9,2.64,16.65,16.65,0,0,1-4.55-2.42,17.12,17.12,0,0,1-3.8-3.8,17.84,17.84,0,0,1,0-21.21,17.08,17.08,0,0,1,3.79-3.8,16.8,16.8,0,0,1,4.55-2.42,17.87,17.87,0,0,1,9.4-.74,17.7,17.7,0,0,1,13.87,13.24,12.44,12.44,0,0,1,.33,1.84,8,8,0,0,1,.11,1.36A6.11,6.11,0,0,1,103.74,383.65Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "86.0303px 383.647px"
      }}
      id="el9eje14g7fva"
      className="animable"
    />
    <path
      d="M93.37,379.94a8.12,8.12,0,1,1-10.92-3.51A8.11,8.11,0,0,1,93.37,379.94Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "86.151px 383.656px"
      }}
      id="el6hus5ktskkb"
      className="animable"
    />
    <path
      d="M91.69,383.65a5.54,5.54,0,1,1-5.54-5.55A5.54,5.54,0,0,1,91.69,383.65Z"
      style={{
        fill: "rgb(245, 245, 245)",
        transformOrigin: "86.15px 383.64px"
      }}
      id="elrfjcndb3sn7"
      className="animable"
    />
    <path
      d="M91.69,383.65c-.06,0-.06-.5-.3-1.33a5.5,5.5,0,0,0-2-2.88,5.24,5.24,0,1,0,0,8.41,5.54,5.54,0,0,0,2-2.88c.24-.83.24-1.33.3-1.32a2.82,2.82,0,0,1,0,1.38,5.48,5.48,0,0,1-2,3.22,5.79,5.79,0,0,1-5.51.91,5.85,5.85,0,0,1,0-11,5.79,5.79,0,0,1,5.51.91,5.48,5.48,0,0,1,2,3.22A2.89,2.89,0,0,1,91.69,383.65Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "86.0494px 383.66px"
      }}
      id="elyv1ryn0mdu"
      className="animable"
    />
    <path
      d="M111.15,359.85c0,.2-11.5.36-25.67.36s-25.67-.16-25.67-.36,11.49-.35,25.67-.35S111.15,359.66,111.15,359.85Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "85.48px 359.855px"
      }}
      id="el656p4zcpd5j"
      className="animable"
    />
    <path
      d="M59.81,347.19a15.64,15.64,0,0,1-1.66.44,6.25,6.25,0,0,0-4.43,6,14.52,14.52,0,0,1,.06,1.72c-.07,0-.44-.57-.56-1.7a6.06,6.06,0,0,1,4.8-6.5C59.13,346.93,59.82,347.11,59.81,347.19Z"
      style={{
        fill: "rgb(250, 250, 250)",
        transformOrigin: "56.5018px 351.197px"
      }}
      id="el8ii2zyezp8h"
      className="animable"
    />
    <path
      d="M78,370.33c.06.11-1.17.71-2.75,2.16a15.06,15.06,0,0,0-4.49,15.18c.54,2.08,1.24,3.25,1.13,3.31s-.26-.24-.57-.78a12.09,12.09,0,0,1-1-2.39,14.49,14.49,0,0,1,4.64-15.7,13.06,13.06,0,0,1,2.18-1.44C77.66,370.4,78,370.29,78,370.33Z"
      style={{
        fill: "rgb(250, 250, 250)",
        transformOrigin: "73.8091px 380.655px"
      }}
      id="el8vagchzf1i9"
      className="animable"
    />
    <path
      d="M111.15,407.74c-.2,0-.36-10.79-.36-24.09s.16-24.1.36-24.1.35,10.79.35,24.1S111.34,407.74,111.15,407.74Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "111.145px 383.645px"
      }}
      id="el6ickzojblxj"
      className="animable"
    />
    <path
      d="M43.71,473.15v-5.63a4.21,4.21,0,0,1,4.22-4.22h0v-1.8a8,8,0,0,1,8-8H113c4.44,0,8,5,8,9.45h2c2.33,0,4.22.48,4.22,2.81v7.43Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "85.465px 463.345px"
      }}
      id="elvk03wzkywnt"
      className="animable"
    />
    <path
      d="M123,462.91c0,.2-16.86.36-37.65.36s-37.66-.16-37.66-.36,16.85-.35,37.66-.35S123,462.72,123,462.91Z"
      style={{
        fill: "rgb(245, 245, 245)",
        transformOrigin: "85.345px 462.915px"
      }}
      id="elhfl1asomll"
      className="animable"
    />
    <polygon
      points="60.33 397 46.15 394.67 46.15 371.8 60.33 369.05 60.33 397"
      style={{
        fill: "rgb(235, 235, 235)",
        transformOrigin: "53.24px 383.025px"
      }}
      id="elbwcnp1chtlp"
      className="animable"
    />
    <g id="elrwoyrre3zh">
      <rect
        x="43.05"
        y="369.46"
        width="3.6"
        height="27.95"
        rx="1.33"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "44.85px 383.435px",
          transform: "rotate(180deg)"
        }}
        className="animable"
      />
    </g>
    <g id="elrqy9v57cbx">
      <path
        d="M37.81,379.11h2a4,4,0,0,1,4,4v2.84a4,4,0,0,1-4,4h-2a0,0,0,0,1,0,0v-10.8A0,0,0,0,1,37.81,379.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "40.81px 384.53px",
          transform: "rotate(180deg)"
        }}
        className="animable"
      />
    </g>
    <path
      d="M46.76,371.7c.19,0,.35,5.13.35,11.46s-.16,11.46-.35,11.46-.36-5.13-.36-11.46S46.56,371.7,46.76,371.7Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "46.755px 383.16px"
      }}
      id="elj5o8tnvqww"
      className="animable"
    />
    <path
      d="M43.25,371.55c.19,0,.35,5.13.35,11.46s-.16,11.45-.35,11.45-.36-5.13-.36-11.45S43.05,371.55,43.25,371.55Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "43.245px 383.005px"
      }}
      id="elfexbz5t416"
      className="animable"
    />
    <path
      d="M60.34,422c-.2,0-.35-13.64-.35-30.45s.15-30.45.35-30.45.35,13.63.35,30.45S60.53,422,60.34,422Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "60.34px 391.55px"
      }}
      id="eljz2y6pu18j"
      className="animable"
    />
    <rect
      x="266.92"
      y="123.92"
      width="48.65"
      height="12.87"
      style={{
        fill: "rgb(245, 245, 245)",
        transformOrigin: "291.245px 130.355px"
      }}
      id="elyamviitslkc"
      className="animable"
    />
    <path
      d="M295,149.93a1.35,1.35,0,0,1,0-.24V149c0-.6,0-1.45,0-2.53,0-2.22-.05-5.42-.08-9.44l.15.15-48.65.09.24-.24h0v12.85l-.24-.24,34.67.11,10.23.06,2.77,0h.72l.26,0h-.23l-.69,0-2.71,0-10.18.07-34.84.11h-.25V137.08h0l.25-.25,48.65.1h.15v.15c0,4.06-.06,7.29-.08,9.53,0,1.07,0,1.9,0,2.5,0,.26,0,.47,0,.63A1.66,1.66,0,0,1,295,149.93Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "270.695px 143.41px"
      }}
      id="elemu53yslaw"
      className="animable"
    />
    <rect
      x="59.89"
      y="211.15"
      width="48.65"
      height="12.87"
      style={{
        fill: "rgb(245, 245, 245)",
        transformOrigin: "84.215px 217.585px"
      }}
      id="el2eitdpzhb2"
      className="animable"
    />
    <path
      d="M88,237.16s0-.09,0-.24l0-.66c0-.6,0-1.45,0-2.53,0-2.22,0-5.42-.08-9.44l.15.15-48.65.09.25-.24h0v6.52q0,3.22,0,6.33l-.24-.24L74,237l10.23.06,2.77,0h.73l.25,0h-.22l-.7,0-2.71,0-10.18.07-34.84.11h-.24v-.24c0-2.07,0-4.18,0-6.33v-6.52h0l.25-.25,48.65.1h.15v.15c0,4.06-.06,7.29-.08,9.53,0,1.07,0,1.9,0,2.5,0,.26,0,.47,0,.63A.85.85,0,0,1,88,237.16Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "63.615px 230.57px"
      }}
      id="elnpnidezg5q"
      className="animable"
    />
    <path
      d="M432.48,67.41a1,1,0,0,1,0-.23v-.66c0-.61,0-1.45,0-2.53,0-2.23-.05-5.42-.08-9.45l.15.15-48.65.1c.11-.11-.25.25.24-.25h0V67.41l-.24-.24,34.67.11,10.23.07,2.77,0,.72,0h.26a.94.94,0,0,1-.23,0h-.69l-2.72,0-10.17.07-34.84.11h-.25V54.55h0c.49-.49.14-.13.25-.25l48.65.1h.15v.15c0,4.07-.06,7.29-.08,9.54,0,1.06,0,1.9,0,2.5l0,.63A1.23,1.23,0,0,1,432.48,67.41Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "408.175px 60.9068px"
      }}
      id="elvpa5ihypjw"
      className="animable"
    />
    <path
      d="M439.08,54.54a.68.68,0,0,1,0-.24c0-.19,0-.41,0-.68,0-.64,0-1.5-.05-2.59,0-2.25-.05-5.44-.09-9.36l.18.18-29.23.07c-.23.22.29-.3.24-.25h0V48.3c0,2.15,0,4.24,0,6.24l-.24-.23,20.91.11,6.1.06,1.64,0,.59,0a3.43,3.43,0,0,1-.53,0l-1.6,0-6,.07-21.07.11h-.23v-.24c0-2,0-4.09,0-6.24V41.68h0l.25-.25,29.23.07h.18v.18c0,4-.07,7.19-.09,9.47,0,1.07,0,1.92-.05,2.54A8.58,8.58,0,0,1,439.08,54.54Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "424.53px 48.045px"
      }}
      id="elou0s0f4pxk8"
      className="animable"
    />
    <path
      d="M425.17,402.81s0-.09,0-.24l0-.66c0-.6,0-1.45,0-2.53,0-2.22-.05-5.41-.09-9.44l.15.15-48.65.1.25-.25h0v6.52c0,2.15,0,4.26,0,6.33l-.24-.24,34.67.11,10.24.07,2.76,0h.73l.25,0H425l-.7,0-2.71,0-10.18.07-34.84.11h-.24v-.24q0-3.1,0-6.33V390h0l.25-.25,48.65.1h.16v.15c0,4.06-.06,7.29-.08,9.53,0,1.07,0,1.9,0,2.5v.63A.85.85,0,0,1,425.17,402.81Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "400.86px 396.33px"
      }}
      id="el60hqqzzk26a"
      className="animable"
    />
    <path
      d="M431.77,389.94a1.41,1.41,0,0,1,0-.25c0-.18,0-.41,0-.68,0-.63,0-1.49,0-2.58,0-2.26-.06-5.44-.1-9.36l.18.17-29.23.07c-.22.23.3-.3.25-.24h0v3.33c0,1.11,0,2.2,0,3.28,0,2.15,0,4.24,0,6.24l-.23-.24,20.91.11,6.09.07,1.64,0,.59,0a3.28,3.28,0,0,1-.53,0l-1.59,0-6,.06-21.07.11h-.24v-.23c0-2,0-4.09,0-6.24v-6.61h0l.25-.25,29.23.07H432v.18c0,4-.07,7.19-.1,9.47,0,1.07,0,1.92,0,2.54S431.78,390,431.77,389.94Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "417.22px 383.365px"
      }}
      id="el7jyf9hjx1i"
      className="animable"
    />
    <path
      d="M116.12,82.93s0-.08,0-.23l0-.66c0-.61,0-1.45,0-2.54,0-2.22,0-5.41-.09-9.44l.15.15-48.64.1c.1-.11-.25.24.24-.25h0V76.6q0,3.23,0,6.33l-.24-.24,34.67.11,10.24.07,2.76,0h.73a1.27,1.27,0,0,1,.25,0l-.22,0h-.69l-2.72,0-10.18.07-34.84.11h-.24V76.6c0-1.08,0-2.16,0-3.25V70.08h0c.5-.5.14-.14.26-.25l48.64.1h.16v.15c0,4.06-.07,7.29-.09,9.53,0,1.07,0,1.9,0,2.5,0,.26,0,.47,0,.64S116.13,82.93,116.12,82.93Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "91.83px 76.4281px"
      }}
      id="el6bck5urbw6f"
      className="animable"
    />
    <path
      d="M430.7,243.05a2.07,2.07,0,0,1,0-.23l0-.66c0-.61,0-1.45,0-2.53,0-2.23,0-5.42-.08-9.45l.15.15-48.65.1.25-.25h0v3.28c0,1.1,0,2.18,0,3.25v6.33l-.24-.24,34.67.11L427,243l2.77,0,.72,0h.26s-.07,0-.22,0h-.7l-2.71,0-10.18.07-34.84.11h-.24v-.25q0-3.11,0-6.33v-6.53h0l.25-.25,48.65.1h.15v.15c0,4.07-.06,7.29-.08,9.54,0,1.06,0,1.9,0,2.5,0,.26,0,.46,0,.63S430.71,243.06,430.7,243.05Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "406.385px 236.5px"
      }}
      id="elrny3dl6iuh"
      className="animable"
    />
  </g>
  <g
    id="freepik--Floor--inject-2"
    className="animable"
    style={{ transformOrigin: "250px 478.58px" }}
  >
    <path
      d="M480.48,474c0,.05-103.2.1-230.47.1S19.52,474,19.52,474s103.18-.11,230.49-.11S480.48,473.93,480.48,474Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "250px 473.995px" }}
      id="elit1mjih9ea"
      className="animable"
    />
    <path
      d="M480.48,483.19c0,.06-103.2.1-230.47.1s-230.49,0-230.49-.1,103.18-.1,230.49-.1S480.48,483.14,480.48,483.19Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "250px 483.19px" }}
      id="elhzb9n7s9h19"
      className="animable"
    />
    <path
      d="M220.09,483.44c-.06,0-.1-2.18-.1-4.86s0-4.86.1-4.86.1,2.18.1,4.86S220.15,483.44,220.09,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "220.09px 478.58px" }}
      id="ele7nu1a1og89"
      className="animable"
    />
    <path
      d="M166.86,483.44c-.06,0-.11-2.18-.11-4.86s.05-4.86.11-4.86.1,2.18.1,4.86S166.91,483.44,166.86,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "166.855px 478.58px" }}
      id="elvgws85efe6"
      className="animable"
    />
    <path
      d="M113.62,483.44c-.06,0-.1-2.18-.1-4.86s0-4.86.1-4.86.1,2.18.1,4.86S113.68,483.44,113.62,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "113.62px 478.58px" }}
      id="elai1ie4hp0a"
      className="animable"
    />
    <path
      d="M60.39,483.44c-.06,0-.11-2.18-.11-4.86s0-4.86.11-4.86.1,2.18.1,4.86S60.44,483.44,60.39,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "60.3851px 478.58px" }}
      id="elydqei7p32p"
      className="animable"
    />
    <path
      d="M273.67,483.44c-.06,0-.11-2.18-.11-4.86s.05-4.86.11-4.86.1,2.18.1,4.86S273.72,483.44,273.67,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "273.665px 478.58px" }}
      id="eltjbaredpg3"
      className="animable"
    />
    <path
      d="M327.24,483.44c-.06,0-.1-2.18-.1-4.86s0-4.86.1-4.86.1,2.18.1,4.86S327.3,483.44,327.24,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "327.24px 478.58px" }}
      id="el92f2exsm3xw"
      className="animable"
    />
    <path
      d="M380.82,483.44c-.06,0-.11-2.18-.11-4.86s.05-4.86.11-4.86.1,2.18.1,4.86S380.87,483.44,380.82,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "380.815px 478.58px" }}
      id="elz0ib7d2fvq"
      className="animable"
    />
    <path
      d="M434.39,483.44c-.06,0-.1-2.18-.1-4.86s0-4.86.1-4.86.1,2.18.1,4.86S434.45,483.44,434.39,483.44Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "434.39px 478.58px" }}
      id="elfc1ki60isvl"
      className="animable"
    />
  </g>
  <g
    id="freepik--Plant--inject-2"
    className="animable"
    style={{ transformOrigin: "417.847px 446.982px" }}
  >
    <path
      d="M417.84,420.05a10.08,10.08,0,0,1,5.23,5.92,17.86,17.86,0,0,1,.7,8c-.59,5.2-3.55,10.46-6.74,14.62-4.38-3.82-5.39-10.5-5.69-13.92-.48-5.4,2-15.46,6.5-14.65"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "417.612px 434.282px"
      }}
      id="elg0b5l4dzwy9"
      className="animable"
    />
    <path
      d="M422.13,457.22a8.62,8.62,0,0,1,2.78-8.19,11.45,11.45,0,0,1,8.43-2.63,4.83,4.83,0,0,1,3.71,1.66,3.69,3.69,0,0,1,.05,3.73,7.36,7.36,0,0,1-2.76,2.72c-3.8,2.39-7.92,4-12.21,2.71"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "429.771px 452.014px"
      }}
      id="ellb6zql1tzhh"
      className="animable"
    />
    <path
      d="M416.5,473.19a7.7,7.7,0,0,1,.16-1.07c.14-.75.32-1.73.53-2.89a30.31,30.31,0,0,1,3-9.24,18.43,18.43,0,0,1,6.63-7.05,12.65,12.65,0,0,1,2.7-1.2,6.46,6.46,0,0,1,.78-.19.83.83,0,0,1,.28,0,17.09,17.09,0,0,0-10.06,8.66,32.63,32.63,0,0,0-3.06,9.11c-.26,1.21-.48,2.19-.64,2.87A5.35,5.35,0,0,1,416.5,473.19Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "423.54px 462.364px" }}
      id="eleuag3kzd0pe"
      className="animable"
    />
    <path
      d="M416.67,427.87a2.43,2.43,0,0,1,.05.46c0,.35,0,.8.08,1.35.07,1.16.13,2.85.2,4.94.14,4.17.24,9.94.21,16.31s-.18,12.14-.35,16.31c-.09,2.09-.17,3.77-.24,4.94,0,.55-.07,1-.09,1.34a2.93,2.93,0,0,1-.06.47,2.51,2.51,0,0,1,0-.47c0-.35,0-.8,0-1.35,0-1.21.07-2.89.12-4.94.1-4.17.22-9.94.25-16.3s0-12.13-.11-16.31c0-2.05-.06-3.72-.08-4.94v-1.34A2.42,2.42,0,0,1,416.67,427.87Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "416.837px 450.93px" }}
      id="el524ilaf58t4"
      className="animable"
    />
    <path
      d="M414.54,460.39a20.15,20.15,0,0,0-11-14.76c-1.46-.7-3.38-1.16-4.58-.07s-.94,3.05-.35,4.57a17.22,17.22,0,0,0,15.83,10.69"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "406.327px 452.865px"
      }}
      id="el3mv55kbzljq"
      className="animable"
    />
    <path
      d="M404.25,450.57a6.54,6.54,0,0,1,.93.41,9.41,9.41,0,0,1,1.06.56c.39.25.87.49,1.33.84a19.16,19.16,0,0,1,1.54,1.15c.54.43,1.07.94,1.63,1.48a22.92,22.92,0,0,1,3.09,4,21.49,21.49,0,0,1,2,4.62,18.94,18.94,0,0,1,.52,2.14,15.65,15.65,0,0,1,.26,1.9,15.11,15.11,0,0,1,.07,1.58,7.43,7.43,0,0,1,0,1.19,6.55,6.55,0,0,1-.11,1c-.09,0,.06-1.45-.2-3.75a15.69,15.69,0,0,0-.3-1.87c-.12-.67-.33-1.37-.54-2.1a23.13,23.13,0,0,0-2-4.52,23.68,23.68,0,0,0-3-3.93,19.65,19.65,0,0,0-1.58-1.49c-.5-.46-1-.82-1.48-1.17C405.54,451.25,404.21,450.65,404.25,450.57Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "410.476px 461.005px"
      }}
      id="eli7pqtnyjkrc"
      className="animable"
    />
  </g>
  <g
    id="freepik--Character--inject-2"
    className="animable"
    style={{ transformOrigin: "212.72px 259.254px" }}
  >
    <g id="ellcts1146yle">
      <rect
        x="270.32"
        y="210.22"
        width="17.18"
        height="36.3"
        rx="2.71"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "278.91px 228.37px",
          transform: "rotate(11.83deg)"
        }}
        className="animable"
      />
    </g>
    <g id="elts96asgs9dc">
      <rect
        x="268.97"
        y="245.56"
        width="12.4"
        height="1.72"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "275.17px 246.42px",
          transform: "rotate(11.83deg)"
        }}
        className="animable"
      />
    </g>
    <g id="el955ojk4ppxa">
      <rect
        x="276.51"
        y="209.54"
        width="12.4"
        height="1.72"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "282.71px 210.4px",
          transform: "rotate(11.83deg)"
        }}
        className="animable"
      />
    </g>
    <path
      d="M287.2,207.37a2.3,2.3,0,0,0-.78-.8,1.07,1.07,0,0,0-1.08,0,1.12,1.12,0,0,0-.43.91,3,3,0,0,0,.2,1c.3,1,.6,2,.91,3l1.93-.67A5.16,5.16,0,0,0,287.2,207.37Zm-.69,1a.86.86,0,0,1-.57,0,1,1,0,0,1-.61-.5.81.81,0,0,1,.05-.77.68.68,0,0,1,.71-.27v0a1,1,0,0,1,.79.63A.75.75,0,0,1,286.51,208.34Z"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "286.453px 208.952px"
      }}
      id="elcbnn25ia9p"
      className="animable"
    />
    <polygon
      points="271.43 222.19 289.62 219.11 286.08 236.01 267.55 240.73 271.43 222.19"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "278.585px 229.92px"
      }}
      id="el90squ1vvxnb"
      className="animable"
    />
    <path
      d="M289.2,211.89a41.85,41.85,0,0,1-6.41-1.08,41.44,41.44,0,0,1-6.31-1.59,40.63,40.63,0,0,1,6.42,1.08A38.51,38.51,0,0,1,289.2,211.89Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "282.84px 210.555px" }}
      id="elqg9evqo9bo"
      className="animable"
    />
    <path
      d="M280.81,247.23a36.71,36.71,0,0,1-6.05-1,35.88,35.88,0,0,1-5.95-1.51,36.71,36.71,0,0,1,6.05,1A36.58,36.58,0,0,1,280.81,247.23Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "274.81px 245.975px" }}
      id="elkpkybpvm9fr"
      className="animable"
    />
    <path
      d="M271.53,229.64a86.91,86.91,0,0,1,1.48-9.37,88.31,88.31,0,0,1,2-9.28,86.51,86.51,0,0,1-1.48,9.37A87.59,87.59,0,0,1,271.53,229.64Z"
      style={{
        fill: "rgb(255, 255, 255)",
        transformOrigin: "273.27px 220.315px"
      }}
      id="elfo9v2crtriq"
      className="animable"
    />
    <path
      d="M229.71,208.9l37.72,20.81-4.78,13s-33.26-9-52.7-21.88Z"
      style={{
        fill: "rgb(255, 213, 189)",
        transformOrigin: "238.69px 225.805px"
      }}
      id="eltreixrrdcw9"
      className="animable"
    />
    <path
      d="M288.5,224.76s-7.56-3.28-8.74-3.65-7.37.67-7.37.67l-7.34,8.64-2.4,12.3a25.73,25.73,0,0,0,7.63,1.42c3.84,0,12-4.35,12-4.35s2.81,1.66,3.25,1.35,2.28-1.92,1.62-3c0,0,2.24-2.36,1.58-3.68l-1.26-1s3.17-2.81.73-4.13C288.18,229.4,291.13,226.74,288.5,224.76Z"
      style={{
        fill: "rgb(255, 213, 189)",
        transformOrigin: "276.121px 232.585px"
      }}
      id="elj3yeg7t388k"
      className="animable"
    />
    <path
      d="M288.15,229c-.09.14-2-1.06-4.57-2.21s-4.71-1.84-4.67-2a18.81,18.81,0,0,1,9.24,4.21Z"
      style={{
        fill: "rgb(255, 178, 148)",
        transformOrigin: "283.53px 226.901px"
      }}
      id="elrnkpz2r8hl"
      className="animable"
    />
    <path
      d="M288,233.81c-.05.07-.48-.18-1.18-.53L284,232a16.83,16.83,0,0,1-3.82-2.19,17.12,17.12,0,0,1,4.07,1.66,25.88,25.88,0,0,1,2.75,1.49C287.68,233.38,288,233.75,288,233.81Z"
      style={{
        fill: "rgb(255, 178, 148)",
        transformOrigin: "284.09px 231.816px"
      }}
      id="elh6l7952qijm"
      className="animable"
    />
    <path
      d="M280.84,233.9a5.85,5.85,0,0,1,3.62,1.29,5.76,5.76,0,0,1,2.49,2.92c-.14.1-1.12-1.29-2.82-2.44S280.8,234.07,280.84,233.9Z"
      style={{
        fill: "rgb(255, 178, 148)",
        transformOrigin: "283.895px 236.008px"
      }}
      id="elqjzjsng6igo"
      className="animable"
    />
    <path
      d="M305.23,274.86c-.09,0-.16-1.61-.51-4.19a53.49,53.49,0,0,0-2.33-9.85,52.5,52.5,0,0,0-4.21-9.21C296.88,249.36,296,248,296,248a4.66,4.66,0,0,1,.73.87c.45.58,1.05,1.45,1.75,2.56a45,45,0,0,1,4.38,9.23,46.08,46.08,0,0,1,2.21,10,29.39,29.39,0,0,1,.18,3.09A4.45,4.45,0,0,1,305.23,274.86Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "300.638px 261.43px" }}
      id="elslbq7e83xo"
      className="animable"
    />
    <path
      d="M307.4,259a22.22,22.22,0,0,1-1.47-2.59c-.88-1.61-2.13-3.81-3.65-6.16s-3-4.41-4.11-5.88a21.22,21.22,0,0,1-1.75-2.41,14,14,0,0,1,2,2.19,58.4,58.4,0,0,1,4.26,5.81,61.6,61.6,0,0,1,3.54,6.28A13.88,13.88,0,0,1,307.4,259Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "301.91px 250.48px" }}
      id="elggconro5j9n"
      className="animable"
    />
    <path
      d="M160.64,422.69l-14,22.5s18.18,21.76,16,26.17l-44.6-25.53,20.34-35.95Z"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "140.431px 440.62px"
      }}
      id="elf01ykutldj"
      className="animable"
    />
    <path
      d="M134,435.36a2.1,2.1,0,0,0-2.45,1.36,2,2,0,0,0,1.32,2.44,2.22,2.22,0,0,0,2.59-1.45,2.09,2.09,0,0,0-1.66-2.39"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "133.482px 437.261px"
      }}
      id="el3xxzopzss4m"
      className="animable"
    />
    <path
      d="M118.06,445.83l2.19-3.54,41.94,25.8s1.49,2,.47,3.27Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "140.538px 456.825px"
      }}
      id="el5k0wugkqyra"
      className="animable"
    />
    <path
      d="M147.52,445.38c-.14.21-1.28-.31-2.75-.23s-2.57.68-2.72.48.89-1.28,2.68-1.35S147.68,445.2,147.52,445.38Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "144.785px 444.974px"
      }}
      id="elgt7lba36e3"
      className="animable"
    />
    <path
      d="M151.09,450c-.08.24-1.23.07-2.5.57s-2,1.35-2.25,1.21.36-1.44,1.95-2S151.2,449.78,151.09,450Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "148.691px 450.667px"
      }}
      id="ele7vovddo75t"
      className="animable"
    />
    <path
      d="M150.54,457.11c-.23-.07.09-1.33,1.33-2.2s2.54-.77,2.53-.53-1,.51-2,1.24S150.77,457.21,150.54,457.11Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "152.435px 455.665px"
      }}
      id="el46v54vy5zy3"
      className="animable"
    />
    <path
      d="M150.13,439.9c-.2.14-1-.73-2.31-1.35s-2.46-.78-2.47-1,1.37-.5,2.86.26S150.34,439.79,150.13,439.9Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "147.759px 438.614px"
      }}
      id="el2xbf89rv4r"
      className="animable"
    />
    <path
      d="M158.7,431.93l-1.51,26.45s26.38,10.41,26.57,15.32l-51.37-1.08.65-41.29Z"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "158.075px 452.515px"
      }}
      id="el7x4kezvfmox"
      className="animable"
    />
    <path
      d="M141.35,455.82a2.09,2.09,0,0,0-1.5,2.36,2,2,0,0,0,2.33,1.51,2.21,2.21,0,0,0,1.58-2.51,2.09,2.09,0,0,0-2.6-1.3"
      style={{
        fill: "rgb(224, 224, 224)",
        transformOrigin: "141.807px 457.755px"
      }}
      id="el193cjiq1qj4"
      className="animable"
    />
    <path
      d="M132.39,472.62l.23-4.16,49.17,2.6s2.27,1,2,2.64Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "158.101px 471.08px" }}
      id="els4xt8i8zrc"
      className="animable"
    />
    <path
      d="M158,458.13c0,.25-1.27.34-2.52,1.12s-1.93,1.82-2.16,1.72.18-1.55,1.71-2.46S158.09,457.9,158,458.13Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "155.632px 459.434px"
      }}
      id="elesvr9ezvmh"
      className="animable"
    />
    <path
      d="M163.37,460.48c.06.24-1,.64-1.91,1.69s-1.15,2.16-1.4,2.14-.38-1.44.74-2.71S163.37,460.24,163.37,460.48Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "161.631px 462.342px"
      }}
      id="elt5f696rzv79"
      className="animable"
    />
    <path
      d="M166.3,467c-.23.05-.55-1.21.12-2.57s1.86-1.89,2-1.67-.64.93-1.18,2.05S166.55,467,166.3,467Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "167.228px 464.857px"
      }}
      id="elfp7t5fykkv"
      className="animable"
    />
    <path
      d="M157.71,452.07c-.11.23-1.26-.14-2.68-.08s-2.53.49-2.66.27,1-1.09,2.63-1.14S157.83,451.87,157.71,452.07Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "155.039px 451.72px" }}
      id="elw05fzgeguw"
      className="animable"
    />
    <path
      d="M136,269s-3.19,14.52-1.77,16.64,3.89,8.86,3.89,8.86L136,360l-6.37,96.31h30.6l13.13-84.93s4.6-7.79,6.37-14.52,12.22-73.64,12.22-73.64v-25.8Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "160.79px 356.865px" }}
      id="el2urpxvieoa3"
      className="animable"
    />
    <path
      d="M205.76,259.17s-6.39,87.75-8.51,95.54-45.62,87.44-45.62,87.44l-26.25-12.88,34.81-84.1V264Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "165.57px 350.66px" }}
      id="el6n7gmid34w"
      className="animable"
    />
    <path
      d="M195.29,266.72a3.75,3.75,0,0,1,0,.5l-.2,1.45c-.2,1.3-.48,3.17-.84,5.57-.76,4.86-1.86,11.84-3.21,20.46L186.15,325c-.92,5.71-1.84,11.7-2.91,17.91a108.5,108.5,0,0,1-4.77,18.91c-2.3,6.29-5.59,12-6.7,18.21-1.32,6.13-2.23,12.14-3.13,17.85-1.73,11.42-3.12,21.74-4.31,30.41s-2.14,15.67-2.87,20.51c-.36,2.42-.66,4.29-.89,5.56q-.16.92-.27,1.44a2.7,2.7,0,0,1-.11.49,2.36,2.36,0,0,1,.05-.5c0-.36.12-.84.21-1.45l.77-5.58c.63-4.88,1.54-11.89,2.66-20.53s2.48-19,4.18-30.44c.88-5.72,1.79-11.72,3.1-17.9,1.13-6.29,4.44-12.07,6.72-18.29a109.07,109.07,0,0,0,4.75-18.8c1.07-6.19,2-12.19,3-17.89,1.87-11.4,3.55-21.67,5-30.31s2.59-15.58,3.4-20.43c.42-2.39.74-4.25,1-5.55q.17-.91.27-1.44A2.7,2.7,0,0,1,195.29,266.72Z"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "177.745px 361.505px"
      }}
      id="elmfoaq9zzwc"
      className="animable"
    />
    <path
      d="M133,404.59a11.71,11.71,0,0,1,0,2c0,1.3-.13,3.18-.26,5.51-.27,4.65-.73,11.08-1.27,18.16s-1,13.51-1.3,18.16c-.15,2.24-.27,4.1-.36,5.51a12.27,12.27,0,0,1-.21,2,12.21,12.21,0,0,1,0-2c0-1.31.07-3.19.16-5.52.19-4.66.58-11.09,1.12-18.18s1.07-13.4,1.45-18.15c.19-2.24.34-4.09.46-5.5A13,13,0,0,1,133,404.59Z"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "131.301px 430.26px"
      }}
      id="elpsh1s5r5t2l"
      className="animable"
    />
    <path
      d="M171.73,72.76l-7.26,48.34c-1.24,8.22-1.38,20.55,11.71,21.91h0c11.44.32,15.9-9.94,17-17.61.54-3.64,1-6.67,1.08-7,0,0,12.48.69,15.2-11.36,1.32-5.82,3.05-18,4.48-29.09a23,23,0,0,0-18-25.45l-1.16-.25C182.08,50.27,173.32,60,171.73,72.76Z"
      style={{
        fill: "rgb(255, 213, 189)",
        transformOrigin: "189.033px 97.5054px"
      }}
      id="el13o2tl9twao"
      className="animable"
    />
    <path
      d="M194.37,118.19a28.73,28.73,0,0,1-14.79-7.83s1.87,9.48,14.15,11Z"
      style={{
        fill: "rgb(255, 178, 148)",
        transformOrigin: "186.975px 115.86px"
      }}
      id="elw453txwdjdq"
      className="animable"
    />
    <path
      d="M210.91,83.54c-.26.2-1.88-.54-4.17-.42s-3.94,1-4.19.81.14-.44.86-.84a7.8,7.8,0,0,1,3.36-.9,7.19,7.19,0,0,1,3.31.58C210.79,83.11,211,83.45,210.91,83.54Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "206.702px 83.0664px"
      }}
      id="ela90kxo6cec"
      className="animable"
    />
    <path
      d="M191.87,81.58c-.32.07-1.44-1.3-3.53-2.2s-4-.87-4.1-1.14c-.08-.12.31-.32,1.14-.36a7.94,7.94,0,0,1,3.38.7,7.14,7.14,0,0,1,2.71,2C192,81.15,192,81.56,191.87,81.58Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "188.086px 79.7309px"
      }}
      id="elvx0w0u3404"
      className="animable"
    />
    <path
      d="M190.79,85.47a1.36,1.36,0,0,1-1.57,1.07,1.31,1.31,0,0,1-1.12-1.49A1.36,1.36,0,0,1,189.67,84,1.29,1.29,0,0,1,190.79,85.47Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "189.445px 85.2696px"
      }}
      id="el9pbazlruf7"
      className="animable"
    />
    <path
      d="M208.69,88.58a1.35,1.35,0,0,1-1.57,1.07A1.29,1.29,0,0,1,206,88.16a1.36,1.36,0,0,1,1.57-1.07A1.3,1.3,0,0,1,208.69,88.58Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "207.344px 88.3708px"
      }}
      id="elsu0961qcnxk"
      className="animable"
    />
    <path
      d="M195.63,94.94a13,13,0,0,1,3.13.1c.48,0,1,0,1.1-.25a2.52,2.52,0,0,0,0-1.47l-.63-3.91c-.86-5.55-1.36-10.08-1.14-10.11s1.1,4.44,2,10c.2,1.37.39,2.67.57,3.92a2.84,2.84,0,0,1-.15,1.91,1.22,1.22,0,0,1-.93.53,3.05,3.05,0,0,1-.83-.06A12,12,0,0,1,195.63,94.94Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "198.195px 87.4831px"
      }}
      id="elcyv7ljn5ykk"
      className="animable"
    />
    <path
      d="M192.66,72.24c-.26.42-1.85-.14-3.82-.31s-3.64,0-3.81-.43c-.07-.22.29-.6,1-.91a6.33,6.33,0,0,1,3-.41,6.15,6.15,0,0,1,2.83,1C192.49,71.59,192.77,72,192.66,72.24Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "188.852px 71.2688px"
      }}
      id="elwwvxh905ve"
      className="animable"
    />
    <path
      d="M211.53,78.69c-.27.34-1.8-.29-3.73-.62s-3.56-.29-3.71-.71c-.06-.19.3-.48,1-.68A6.73,6.73,0,0,1,208,76.6a7,7,0,0,1,2.74,1.09C211.39,78.13,211.65,78.53,211.53,78.69Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "207.821px 77.633px" }}
      id="elf9lcxdfcpqm"
      className="animable"
    />
    <path
      d="M170.82,81.91c-.19-.13-7.78-4.22-9.27,4.1s7.16,8.09,7.21,7.86S170.82,81.91,170.82,81.91Z"
      style={{
        fill: "rgb(255, 213, 189)",
        transformOrigin: "166.099px 87.3684px"
      }}
      id="elpybzzn2yt1"
      className="animable"
    />
    <path
      d="M166.79,90.26s-.16.08-.43.15a1.49,1.49,0,0,1-1.08-.18c-.82-.48-1.31-2-1.08-3.51a4.87,4.87,0,0,1,.77-2,1.74,1.74,0,0,1,1.29-.92.76.76,0,0,1,.79.55c.07.26,0,.41,0,.44s.21-.11.2-.47a.9.9,0,0,0-.23-.58,1.13,1.13,0,0,0-.76-.35,2.07,2.07,0,0,0-1.75,1,5,5,0,0,0-.92,2.23c-.24,1.69.33,3.41,1.47,4a1.59,1.59,0,0,0,1.37,0C166.74,90.46,166.82,90.28,166.79,90.26Z"
      style={{
        fill: "rgb(255, 178, 148)",
        transformOrigin: "165.393px 87.0826px"
      }}
      id="elfsoll7a3hyd"
      className="animable"
    />
    <path
      d="M192.86,100.46c.12-.2-1.45-.91-2.39-2.61a9.56,9.56,0,0,1-.86-2.34c-.16-.64-.29-1.05-.39-1s-.12.43-.08,1.1a6.28,6.28,0,0,0,.74,2.6,5.24,5.24,0,0,0,1.91,1.93C192.4,100.45,192.83,100.53,192.86,100.46Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "190.994px 97.4947px"
      }}
      id="elarkgtn0qsfl"
      className="animable"
    />
    <path
      d="M231.69,69.19a2,2,0,0,0-.77-1.28,6.16,6.16,0,0,0-3-.93,17.79,17.79,0,0,1-8.78-4.05,42,42,0,0,0-3.55-5.76c-3.18-4.32-5.86-7.33-8.83-9-3.84-2.19-10.55-3.79-15-3.26s-8.67,3.44-9.86,7.7c-2.1-2.33-5.68-2.81-8.73-2l-.07,0c-3.39.7-5.69,3.86-7.09,7s-2.3,6.62-4.45,9.33c-.45.56-1,1.24-.75,1.93a1.36,1.36,0,0,0,1.34.81,4,4,0,0,0,1.59-.47l-1.43,2.44a7.85,7.85,0,0,0,3.54.25,74.34,74.34,0,0,0,1.1,9,5,5,0,0,1,3.3,1.31c1,1.17,1.23,3.15,3.21,2.68,1-.23,2.15-2.12,2.61-3.05a48,48,0,0,0,4.83-19.85,15.46,15.46,0,0,1,5.19-2.76l-.16.11c3.57-.71,7.58-1.35,10.59.7,2.34,1.58,3.46,4.39,5,6.72a19.37,19.37,0,0,0,12.78,8.18c-.29,3.46-1.25,8.27-1.54,11.73l3.64-5.11v0c.29-.37.54-.75.79-1.13l.08-.11v0c.39-.61.77-1.22,1.24-1.82l0,0A2.17,2.17,0,0,0,222.1,76a4.47,4.47,0,0,0-.89-1.18,3.43,3.43,0,0,0,3.12,1.28,2.68,2.68,0,0,0,2.24-2.37,2.31,2.31,0,0,0-1.1-2,7.62,7.62,0,0,0,4.48-.36C230.88,71,231.83,70.31,231.69,69.19Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "196.226px 65.739px" }}
      id="elzqlq7tmgro"
      className="animable"
    />
    <path
      d="M185.54,54.51a11.22,11.22,0,0,0-1.37,1,8.81,8.81,0,0,0-2.43,3.79,15.41,15.41,0,0,0-.74,3.06c-.16,1.12-.24,2.34-.33,3.62a32.59,32.59,0,0,1-.45,4,14.51,14.51,0,0,1-.57,2.07,4.46,4.46,0,0,1-1.14,1.93,1.85,1.85,0,0,1-1.8.34,2.74,2.74,0,0,1-1.43-1.09,6.41,6.41,0,0,1-.75-3.26l.25.06A10,10,0,0,1,173,72.34a3.66,3.66,0,0,1-2.55,1,2.36,2.36,0,0,1-1.23-.39,2.09,2.09,0,0,1-.74-1,2.17,2.17,0,0,1-.11-1.12,1.48,1.48,0,0,1,.53-.89l.11.13a7.89,7.89,0,0,1-2.89,1.6,6.82,6.82,0,0,1-.86.21,1.12,1.12,0,0,1-.3,0,1.94,1.94,0,0,1,.29-.08c.2,0,.48-.12.84-.24a8.15,8.15,0,0,0,2.8-1.65l.12.13a1.58,1.58,0,0,0-.33,1.8,1.74,1.74,0,0,0,.67.85,2.18,2.18,0,0,0,1.11.35,3.41,3.41,0,0,0,2.37-.93,10.1,10.1,0,0,0,1.77-2.3l.23-.37,0,.43a6.15,6.15,0,0,0,.72,3.12,2.41,2.41,0,0,0,1.27,1,1.57,1.57,0,0,0,1.53-.28,4.24,4.24,0,0,0,1.06-1.81,14.94,14.94,0,0,0,.56-2,33.68,33.68,0,0,0,.47-4c.1-1.28.18-2.5.35-3.63a15.59,15.59,0,0,1,.79-3.1,8.66,8.66,0,0,1,2.54-3.8A4.93,4.93,0,0,1,185.54,54.51Z"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "175.25px 64.4653px"
      }}
      id="el82ezthzj0zv"
      className="animable"
    />
    <path
      d="M221.63,71.57a.46.46,0,0,1-.11.07l-.33.18a5.39,5.39,0,0,1-.57.26,5.82,5.82,0,0,1-.79.29,10.91,10.91,0,0,1-5.35.22,14,14,0,0,1-7-3.73,24.06,24.06,0,0,1-5.32-8.16c-.69-1.6-1.2-3.21-1.81-4.72a16.33,16.33,0,0,0-2.21-4.09,8.45,8.45,0,0,0-3.22-2.61,6.12,6.12,0,0,0-1.82-.47,6.62,6.62,0,0,0-1.74.09,7.19,7.19,0,0,0-2.76,1.18,7,7,0,0,0-1.62,1.6,8.2,8.2,0,0,0-.88,1.74s0,0,0-.13l.11-.36a6.21,6.21,0,0,1,.65-1.32A6.94,6.94,0,0,1,188.53,50a7.28,7.28,0,0,1,2.81-1.25,6.64,6.64,0,0,1,1.8-.11,6,6,0,0,1,1.89.47,8.58,8.58,0,0,1,3.34,2.67,16.73,16.73,0,0,1,2.27,4.15c.61,1.52,1.12,3.13,1.8,4.71a24.24,24.24,0,0,0,5.24,8.08,13.92,13.92,0,0,0,6.84,3.72,11.09,11.09,0,0,0,5.28-.13A17.89,17.89,0,0,0,221.63,71.57Z"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "203.865px 60.7219px"
      }}
      id="elzi3sot73a4"
      className="animable"
    />
    <path
      d="M223.48,66.36a1.64,1.64,0,0,1-.48.25,11.22,11.22,0,0,1-1.39.48,13.1,13.1,0,0,1-2.14.39,13.11,13.11,0,0,1-5.28-.56,12.4,12.4,0,0,1-2-.82,9.08,9.08,0,0,1-1.25-.76c-.28-.2-.43-.32-.42-.34s.65.39,1.76.91a13.17,13.17,0,0,0,2,.76,15.8,15.8,0,0,0,2.57.51,16.57,16.57,0,0,0,2.62,0,14.91,14.91,0,0,0,2.11-.33C222.75,66.61,223.46,66.31,223.48,66.36Z"
      style={{ fill: "rgb(69, 90, 100)", transformOrigin: "217px 66.2713px" }}
      id="el9ec6gqm5o8p"
      className="animable"
    />
    <path
      d="M265.34,228.53c-.1.12-1-.48-2.1-1.05s-2.12-.91-2.08-1.06a3.79,3.79,0,0,1,2.32.6C264.68,227.62,265.43,228.43,265.34,228.53Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "263.253px 227.478px"
      }}
      id="elkz91t14wxhi"
      className="animable"
    />
    <path
      d="M204.49,200.55c0-1.54-11-70.14-11-70.14-2.27-2.25-27.94-21.77-27.94-21.77l-23,26.53s-6.08,21-8.13,36.28c-1.34,10.07-7.51,58.09-9.4,78.37a15.44,15.44,0,0,0,6.38,14l2.37.29.51,6.07c20.77.88,71.09-7.37,71.71-7C206.8,263.7,204.34,206.11,204.49,200.55Z"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "165.553px 189.443px"
      }}
      id="elg8mk3dme2xd"
      className="animable"
    />
    <path
      d="M160.59,159.06s22.59,59.15,35.23,66.41,51.35,18,51.35,18l3.77-1.61,6.45,1.61,10.22-18.82-48.67-25s-23.55-62.56-25.22-63.82-6-8.51-16.72-3.94S157.37,150.93,160.59,159.06Z"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "213.766px 186.933px"
      }}
      id="elvnsgedftup"
      className="animable"
    />
    <path
      d="M206.18,255.69a3.61,3.61,0,0,1-.72.18l-2.07.42c-1.81.37-4.43.83-7.68,1.35-6.49,1.06-15.51,2.23-25.5,3.27s-19,1.85-25.58,2.47l-7.76.71-2.11.18a3.45,3.45,0,0,1-.74,0,4,4,0,0,1,.73-.13l2.1-.28c1.82-.23,4.47-.53,7.74-.88l25.57-2.61c10-1,19-2.16,25.5-3.12,3.25-.48,5.88-.89,7.69-1.19l2.09-.32A3.71,3.71,0,0,1,206.18,255.69Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "170.1px 259.989px" }}
      id="elr68ri15uhvs"
      className="animable"
    />
    <path
      d="M165.58,139a6.37,6.37,0,0,0-.88,1.21,14.21,14.21,0,0,0-1.24,4.12,30.68,30.68,0,0,0-.39,6.82,33.94,33.94,0,0,0,2.41,8.72c4.58,12.69,11.35,30.08,19.55,49,1,2.37,2,4.69,3.19,6.91a31.69,31.69,0,0,0,3.95,6.17,23.68,23.68,0,0,0,5.68,4.15c2.05,1.11,4.1,2.11,6.15,3,8.2,3.5,15.86,6,22.28,8.05s11.66,3.6,15.28,4.67l4.17,1.24,1.08.33.36.13a1.32,1.32,0,0,1-.37-.08l-1.1-.28L241.51,242c-3.64-1-8.89-2.52-15.35-4.51s-14.11-4.47-22.35-8c-2.06-.87-4.13-1.87-6.2-3a24.19,24.19,0,0,1-5.82-4.25,32.41,32.41,0,0,1-4-6.27c-1.16-2.24-2.2-4.57-3.21-6.94-8.2-18.9-14.92-36.33-19.42-49.06a34.09,34.09,0,0,1-2.35-8.81,30.46,30.46,0,0,1,.48-6.88,13.44,13.44,0,0,1,1.35-4.14A3.51,3.51,0,0,1,165.58,139Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "204.975px 191.26px" }}
      id="el54mma2ppfx3"
      className="animable"
    />
    <path
      d="M218.94,199.66c.08.13-2,1.27-4.1,3.32s-3.35,4.05-3.48,4a12.22,12.22,0,0,1,3.12-4.34A11.3,11.3,0,0,1,218.94,199.66Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "215.151px 203.32px" }}
      id="elovb4d5hy4s"
      className="animable"
    />
    <path
      d="M155.1,106.76c-3.63,1-6.69,3.36-9.63,5.7l-12.7,10.1c-2.46,1.94-5,4-6.15,7-1.62,4.19.31,9.23,3.86,12s8.35,3.53,12.82,3,8.68-2.32,12.79-4.18l10.4-31C164.38,106.2,158.72,105.79,155.1,106.76Z"
      style={{
        fill: "rgb(207, 216, 220)",
        transformOrigin: "146.265px 125.507px"
      }}
      id="elwajrf34f9x9"
      className="animable"
    />
    <path
      d="M193.83,130.67a6.44,6.44,0,0,1-1.32-.57c-.84-.4-2-1-3.48-1.82a102.75,102.75,0,0,1-10.88-7c-4.08-3-7.64-5.87-10.23-7.93l-3-2.47a6.86,6.86,0,0,1-1.07-1,7.71,7.71,0,0,1,1.2.8c.75.53,1.83,1.34,3.15,2.33,2.64,2,6.24,4.83,10.31,7.81s7.9,5.46,10.75,7.14c1.42.84,2.58,1.51,3.39,2A7.24,7.24,0,0,1,193.83,130.67Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "178.84px 120.275px" }}
      id="eltthqnm7ywt"
      className="animable"
    />
    <path
      d="M158.84,120.28a9.21,9.21,0,0,1-1.19.94c-.4.28-.87.64-1.41,1.07s-1.16.91-1.81,1.48a46.06,46.06,0,0,0-9,10.08,47.54,47.54,0,0,0-5.67,12.25c-.47,1.66-.85,3-1,4a7.7,7.7,0,0,1-.41,1.46,9,9,0,0,1,.21-1.5c.14-1,.46-2.34.9-4A44.22,44.22,0,0,1,145,133.56a42.9,42.9,0,0,1,9.17-10.07c.68-.56,1.31-1,1.88-1.43s1.05-.75,1.46-1A8.16,8.16,0,0,1,158.84,120.28Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "148.595px 135.92px" }}
      id="elhf38ajrg2r"
      className="animable"
    />
    <g id="elaubmbmpbvml">
      <g
        style={{ opacity: "0.3", transformOrigin: "149.375px 115.678px" }}
        className="animable"
      >
        <path
          d="M158.31,107.4c-9,.9-18.67,10.65-26.05,16.47,3,.68,7.68-2.26,10.43-3.59,6.9-3.34,12-8.85,21.11-10.4l2.69-.58C164.93,107.85,160.27,107.28,158.31,107.4Z"
          id="eltigvmdd6oy"
          className="animable"
          style={{ transformOrigin: "149.375px 115.678px" }}
        />
      </g>
    </g>
    <path
      d="M163.49,109.85s-.49.08-1.35.27a15.63,15.63,0,0,0-3.51,1.27c-2.84,1.41-6.49,3.89-10.63,6.45a58.18,58.18,0,0,1-11.13,5.64,29.54,29.54,0,0,1-3.64,1,6,6,0,0,1-1.38.2,10,10,0,0,1,1.33-.4c.85-.25,2.08-.62,3.56-1.19a66.71,66.71,0,0,0,11-5.74,118.93,118.93,0,0,1,10.74-6.34,13.92,13.92,0,0,1,3.63-1.14,6.67,6.67,0,0,1,1-.09C163.37,109.82,163.49,109.83,163.49,109.85Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "147.67px 117.23px" }}
      id="el0nxypuh7uzif"
      className="animable"
    />
    <path
      d="M196.23,142.93c-.09,0-.33-1.08-1.1-2.71a15.38,15.38,0,0,0-10.5-8.49c-1.75-.41-2.88-.42-2.88-.51a2.51,2.51,0,0,1,.79-.07,11.83,11.83,0,0,1,2.16.22,14.55,14.55,0,0,1,10.77,8.7,12.63,12.63,0,0,1,.66,2.07A2.14,2.14,0,0,1,196.23,142.93Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "188.992px 137.037px"
      }}
      id="elcoctkwjqkwc"
      className="animable"
    />
    <path
      d="M259.91,238.73a8.44,8.44,0,0,1-2.43-.91,8.85,8.85,0,0,1-2.32-1.18,5.55,5.55,0,0,1,2.53.7A5.48,5.48,0,0,1,259.91,238.73Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "257.535px 237.685px"
      }}
      id="elku9o1p5twih"
      className="animable"
    />
    <path
      d="M262.92,233.8a43.26,43.26,0,0,1-4.75-2.77,5.75,5.75,0,0,1,2.6,1A5.82,5.82,0,0,1,262.92,233.8Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "260.545px 232.415px"
      }}
      id="elgmcskrovbil"
      className="animable"
    />
    <g id="el11wvlj9jnaf">
      <g
        style={{ opacity: "0.3", transformOrigin: "184.641px 195.635px" }}
        className="animable"
      >
        <path
          d="M164.34,156.78c3.12,23.74,6.65,48.56,20.67,68,3.7,5.12,9,10.15,15.3,9.68,2.52-.19,5.43-2.37,4.43-4.69,0,.23-2.71-1.14-4.62-2.44-4.19-2.83-7.9-4-10.58-8.34s-4.87-10.19-6.82-14.86c-6.12-14.66-13.44-32.41-18.38-47.32"
          id="elpdeiwgqby38"
          className="animable"
          style={{ transformOrigin: "184.641px 195.635px" }}
        />
      </g>
    </g>
    <path
      d="M151,270.42a8.13,8.13,0,0,1-1.07-3.62,8.31,8.31,0,0,1,.18-3.77c.15,0,.09,1.68.34,3.71S151.1,270.38,151,270.42Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "150.405px 266.725px"
      }}
      id="elejktw8w38nv"
      className="animable"
    />
    <path
      d="M160.44,268.74a22.68,22.68,0,0,1-.63-6.77,11.58,11.58,0,0,1,.58,3.36A11.1,11.1,0,0,1,160.44,268.74Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "160.16px 265.355px" }}
      id="elun3vnhkc56"
      className="animable"
    />
    <path
      d="M171.51,267a11.57,11.57,0,0,1-.8-3.34,11.1,11.1,0,0,1-.28-3.43,11.5,11.5,0,0,1,.79,3.34A11.71,11.71,0,0,1,171.51,267Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "170.966px 263.615px"
      }}
      id="eld0vw5s0ks0o"
      className="animable"
    />
    <path
      d="M181.18,266.41a28.11,28.11,0,0,1-.68-3.29,27.93,27.93,0,0,1-.8-3.27A7.18,7.18,0,0,1,181,263,7,7,0,0,1,181.18,266.41Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "180.505px 263.13px" }}
      id="el8txzp0db9uf"
      className="animable"
    />
    <path
      d="M191,264.71a8.39,8.39,0,0,1-.77-2.82A7.94,7.94,0,0,1,190,259a8.46,8.46,0,0,1,.77,2.81A8.26,8.26,0,0,1,191,264.71Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "190.498px 261.855px"
      }}
      id="elttxujupqwy"
      className="animable"
    />
    <path
      d="M199.81,264.3c-.15,0-.2-1.64-.56-3.61s-.87-3.53-.73-3.58a11.17,11.17,0,0,1,1.29,7.19Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "199.221px 260.705px"
      }}
      id="elfpssqzvsw1v"
      className="animable"
    />
    <path
      d="M186.22,221.46a4.35,4.35,0,0,1,.14,1.17,9.92,9.92,0,0,1,0,1.39,15.19,15.19,0,0,1-.08,1.84,26.44,26.44,0,0,1-.82,4.73,30,30,0,0,1-2.13,5.49,30.69,30.69,0,0,1-3.23,4.91,26.75,26.75,0,0,1-3.38,3.42c-.5.46-1,.81-1.45,1.14a10.45,10.45,0,0,1-1.15.77,5.52,5.52,0,0,1-1,.57c-.06-.09,1.39-.95,3.4-2.75a30,30,0,0,0,3.24-3.45A29.11,29.11,0,0,0,185,230.46a29.44,29.44,0,0,0,.92-4.63C186.26,223.15,186.12,221.46,186.22,221.46Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "179.751px 234.175px"
      }}
      id="elk4xg39jhik"
      className="animable"
    />
    <g id="elyu0suw51qqk">
      <g
        style={{ opacity: "0.3", transformOrigin: "181.445px 235.96px" }}
        className="animable"
      >
        <path
          d="M177,243.68l.33-.26a26.4,26.4,0,0,0,8.56-15.18C181.47,232,178.31,238.08,177,243.68Z"
          id="elbl3hbd0a12i"
          className="animable"
          style={{ transformOrigin: "181.445px 235.96px" }}
        />
      </g>
    </g>
    <path
      d="M174.51,260.08a15.44,15.44,0,0,1-1.89-11.34,50.29,50.29,0,0,0,.36,5.76A52.34,52.34,0,0,0,174.51,260.08Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "173.385px 254.41px" }}
      id="elk2nwz0v1i3k"
      className="animable"
    />
    <path
      d="M190.5,219.67c.06.13-.77.62-1.84,1.09a4.63,4.63,0,0,1-2,.62c-.05-.14.77-.62,1.84-1.1A4.57,4.57,0,0,1,190.5,219.67Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "188.58px 220.525px" }}
      id="elkpmodcacvwa"
      className="animable"
    />
    <path
      d="M142.2,270.46a6.05,6.05,0,0,1-1.27-3.41,5.9,5.9,0,0,1,.48-3.6c.15,0-.19,1.61,0,3.55S142.34,270.39,142.2,270.46Z"
      style={{
        fill: "rgb(38, 50, 56)",
        transformOrigin: "141.526px 266.955px"
      }}
      id="elswyrddrz9h"
      className="animable"
    />
  </g>
  <g
    id="freepik--Trash--inject-2"
    className="animable animator-active"
    style={{ transformOrigin: "305.235px 379.715px" }}
  >
    <path
      d="M262.5,285.73l-17.2,37.85,14.79,137.94a6.23,6.23,0,0,0,6.19,5.56H347a6.23,6.23,0,0,0,6.2-5.61L366.75,324l-17-38.27Z"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "306.025px 376.405px"
      }}
      id="el6fbymv43g7m"
      className="animable"
    />
    <path
      d="M366.28,327.07H244.18a3.66,3.66,0,0,1-3.65-3.66h0a3.66,3.66,0,0,1,3.65-3.65h122.1a3.66,3.66,0,0,1,3.66,3.65h0A3.66,3.66,0,0,1,366.28,327.07Z"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "305.235px 323.415px"
      }}
      id="elcknf73binx"
      className="animable"
    />
    <polygon
      points="279.81 285.73 274.07 313.37 340.22 313.37 335.47 285.73 279.81 285.73"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "307.145px 299.55px" }}
      id="eleepru3yijaj"
      className="animable"
    />
    <rect
      x="266.78"
      y="467.08"
      width="11.87"
      height="6.62"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "272.715px 470.39px"
      }}
      id="ellsunyv1mze"
      className="animable"
    />
    <rect
      x="334.29"
      y="467.08"
      width="11.87"
      height="6.62"
      style={{
        fill: "rgb(69, 90, 100)",
        transformOrigin: "340.225px 470.39px"
      }}
      id="el99wgf57o1oo"
      className="animable"
    />
    <path
      d="M340.22,361.5s0,.17,0,.49,0,.84,0,1.48c0,1.32,0,3.25,0,5.75,0,5.06,0,12.43.06,21.73,0,18.67.07,45.08.12,76.13v.24h-.24l-67.5,0h-.26v-.26c0-39.95,0-76.74.05-105.58v-.2h.21l49.28.11,13.52,0,3.52,0,1.18,0-1.23,0-3.55,0-13.56.05-49.16.11.2-.21c0,28.84,0,65.63.06,105.58l-.26-.26h0l67.5,0-.24.24c0-31,.09-57.34.12-76,0-9.32,0-16.7.06-21.77,0-2.53,0-4.48,0-5.81,0-.65,0-1.15,0-1.5S340.22,361.5,340.22,361.5Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "306.4px 414.3px" }}
      id="el4n7h0bjue5v"
      className="animable"
    />
    <path
      d="M366.37,326.69c0,.15-27.16.26-60.65.26s-60.65-.11-60.65-.26,27.15-.26,60.65-.26S366.37,326.55,366.37,326.69Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "305.72px 326.69px" }}
      id="elqdi4hjzspsg"
      className="animable"
    />
    <path
      d="M366.37,319.76c0,.14-27.16.26-60.65.26s-60.65-.12-60.65-.26,27.15-.26,60.65-.26S366.37,319.62,366.37,319.76Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "305.72px 319.76px" }}
      id="elt354pc7lzdo"
      className="animable"
    />
    <path
      d="M347,467.12c0,.14-18.36.26-41,.26s-41-.12-41-.26,18.35-.26,41-.26S347,467,347,467.12Z"
      style={{ fill: "rgb(38, 50, 56)", transformOrigin: "306px 467.12px" }}
      id="el8lkbr7tzxg4"
      className="animable"
    />
  </g>
  <defs>
    {" "}
    <filter id="active" height="200%">
      {" "}
      <feMorphology
        in="SourceAlpha"
        result="DILATED"
        operator="dilate"
        radius={2}
      />{" "}
      <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />{" "}
      <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{" "}
      <feMerge>
        {" "}
        <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{" "}
      </feMerge>{" "}
    </filter>{" "}
    <filter id="hover" height="200%">
      {" "}
      <feMorphology
        in="SourceAlpha"
        result="DILATED"
        operator="dilate"
        radius={2}
      />{" "}
      <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />{" "}
      <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{" "}
      <feMerge>
        {" "}
        <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{" "}
      </feMerge>{" "}
      <feColorMatrix
        type="matrix"
        values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
      />{" "}
    </filter>
  </defs>
</svg>



                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="deleted-content-left text-center ">
                                
                                        <div className="deleted-content pb-2 ">
                                            <h4>
                                               {t("deleted-con")}
                                            </h4>
                                            <button
                                className="btn px-4 btn-outline-dark mt-4"
                                onClick={handleClose}
                                type="button"
                            >
                               OK
                            </button>
                                        </div>
                                       
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                   
                </div>
            </Modal>
        </>
    );
};

export { DeleteModal };
