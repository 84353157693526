// import { toAbsoluteUrl } from "../../../../_start/helpers"
import { useNavigate } from 'react-router-dom';
import expired from "../../assets/images/expired.png"
import { useTranslation } from "react-i18next";
const Expired = () => {
    const [t]=useTranslation("global");
    const navigate = useNavigate();
    return (
      
        <div className="container-fluid cus-height">
            <div className="px-1 px-md-5 d-flex justify-content-center mt-sm-4">
                <div className="col-12 col-md-10 col-xl-8  row justify-content-center p-3">
                    <div className="row align-items-center flex-column flex-column-reverse flex-sm-row detail-shadow px-md-4">
                        <div className="col-12 col-md-6">
                            <h2 className="pb-2">
                                {t("oops")} <br />
                               {t("valid")}
                            </h2>
                            <p className="exp-con">
                                {t("expired")} <br />
                                {t("revoked")}
                            </p>
                            <button style={{"borderRadius":"50px"}} className="btn btn-dark my-2 py-2 px-5" onClick={()=>navigate("/")}>
                                {t("home")}
                            </button>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="expired-img">
                                <img src={expired} alt="expired" />
                                {/* <img src={notFound} alt="expired" /> */}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
  
 

    )
}

export default Expired