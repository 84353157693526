import CryptoJS from 'crypto-js';

// A strong 32-byte key for AES-256
const SECRET_KEY = '12345';

// Base64 URL encoding
const base64UrlEncode = (str) => {
  return str.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

// Base64 URL decoding
const base64UrlDecode = (str) => {
  let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  // Pad with '=' to make the length of the string a multiple of 4
  while (base64.length % 4) {
    base64 += '=';
  }
  return base64;
};

export const encrypt = (text)  => {
  try {
    const encrypted = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
    const base64UrlEncrypted = base64UrlEncode(encrypted);
    return base64UrlEncrypted;
  } catch (error) {
    console.error('Encryption error:', error);
    throw new Error('Encryption failed');
  }
};

export const decrypt = (ciphertext) => {
  try {
    const base64Encrypted = base64UrlDecode(ciphertext);
    const bytes = CryptoJS.AES.decrypt(base64Encrypted, SECRET_KEY);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (error) {
    console.error('Decryption error:', error);
    throw new Error('Decryption failed');
  }
};
