
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import { EmbedWrapper } from './components/EmbedPage/EmbedWrapper';
import { Profile } from './components/ProfilePage/Profile';
import { ProfileDashboardWrapper } from './components/Profile-Dash/ProfileDashboardWrapper';
import { ExpiredWrapper } from './components/ExpiredPage/ExpiredWrapper';
import { StartDashboardWrapper } from './components/StartDashPage/StartDashboardWrapper';
import MyFundraisers from './components/MyFundRaiserPage/MyFundraisers';
import { ViewDetailWrapper } from './components/ViewDetailPage/ViewDetailWrapper';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Donate from "./components/PaymentPage/Donate";
import Donate2 from "./components/PaymentPage/Doante2";
function App() {

  return (


    
    <div className="App">

      <BrowserRouter>
      <Header/>
      <Routes>
      <Route  path='/' Component={StartDashboardWrapper} />
      <Route  path='/profile' Component={Profile} />
      <Route  path='/profile-dash/:id' Component={ProfileDashboardWrapper} />
      <Route  path='*' Component={ExpiredWrapper} />
      <Route  path='/embed' Component={EmbedWrapper} />
      <Route  path='/myfundraiser' Component={MyFundraisers} />
      <Route  path='/viewdetail' Component={ViewDetailWrapper} />
      <Route  path='/donate' Component={Donate} />
      <Route  path='/donate-2' Component={Donate2} />

      </Routes>
      <Footer/>
      </BrowserRouter>

      <ToastContainer
        />
     </div>


  );

}

export default App;
