import { useEffect, useState } from "react"
import { Us ,De ,Fr} from "react-flags-select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown} from '@fortawesome/free-solid-svg-icons';


type Props = {
  selected:any,
  setSelected:any,
  selectedLang: any
};
const LangComp: React.FC<Props> = ({ selected ,setSelected, selectedLang }) => {

  const [t,i18n]=useTranslation("global")

  const handleChangeLanguage=(lang:string) =>{
      localStorage.setItem("selectedLang", lang);
      i18n.changeLanguage(lang);
      console.log(t);
    }
  const [isActive , setIsActive]  = useState(false)
  // const lang = [ ,'English' ,'<De />Detusch']
  const languages = [
    {
      langIcon:<Us /> ,
      langTitle:'EN',
      langValue:"en"
    },
    {
      langIcon:<De />,
      langTitle:'DE',
      langValue:"de"
    },
    {
      langIcon:<Fr />,
      langTitle:'FR',
      langValue:"fr"
    }
  ]

  const languageMap = languages.reduce((map, language) => {
    map[language.langValue] = language;
    return map;
  }, {});

  useEffect(() => {
      // console.log(localStorage.getItem("selectedLang"));
      if(localStorage.getItem("selectedLang")) {
        // console.log(languageMap[localStorage.getItem("selectedLang")].langTitle);
        setSelected(([languageMap[localStorage.getItem("selectedLang")].langIcon, languageMap[localStorage.getItem("selectedLang")].langTitle]));
      }
      i18n.changeLanguage(localStorage.getItem("selectedLang"));
    
  }, [i18n,languageMap,setSelected]);
  
  return (
  <div className="language-dropdown me-3">
    <div className="language-btn" onClick={(e)=> setIsActive(!isActive)}>
    
     {selected}
     <FontAwesomeIcon icon={faCaretDown} className="ms-2"/>
    </div>
 {isActive &&    <div className="language-content">
  {languages.map(lang =>(

      <div className="language-item" 
        onClick={(e) => {
        setSelected(([lang.langIcon,lang.langTitle])) 
        setIsActive(false) 
        handleChangeLanguage(lang.langValue)
        }} >
      <span > {lang.langIcon}  {lang.langTitle}</span>
      </div>
  ))}

    </div>}
  </div>
  )
}

export default LangComp