import { useTranslation } from "react-i18next";

interface User {
  id: number;
  title: string;
  content: string;
}
const StepCard: React.FC = () =>{
  const [t]=useTranslation("global")

  const Users:User[]=[
    {
      id:1,
      title:"ct1",
      content:"cc1"
    },
    {
      id:2,
      title:"ct2",
      content:"cc2"
    },
    // {
    //   id:3,
    //   title:"ct3",
    //   content:"cc3"
    // },
    {
      id:3,
      title:"ct4",
      content:"cc4"
    },
 
  
  ]
  return (
    <div className="row my-3 justify-content-center">
    {Users.map((e) => (
      <div className="col-md-6 col-xl-4 my-2 ">
       <div className="fund-card p-3 position-relative h-100">
         <h5 className="fund-card-title">{t(e.title)}</h5>
         <p className="card-content">
         {t(e.content)}
         </p>
         <div className="fund-card-num position-absolute top-0 end-0">
           <p className="num">{e.id}</p>
         </div>
       </div>
     </div>
     ))}
   
  
  
    </div>
  );

}

export default StepCard