
import { Modal } from "react-bootstrap";
import f1 from "../../assets/images/f1.png"
import { useTranslation } from "react-i18next";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const EmbedModal: React.FC<Props> = ({ show, handleClose }) => {
  const [t]=useTranslation("global")


  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered modal-xl h-auto"
      show={show}
      backdrop="static"
      onHide={handleClose}
    >
      <div className="container pt-10">
        <div className="modal-header py-2 ">
          {/* begin::Close */}
          <h5 className="modal-title text-center">{t("share-tit")}</h5>
          <div className="d-flex justify-content-end border-0">

            <div
              className="btn btn-icon btn-sm btn-light-primary"
              onClick={handleClose}
            >
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" id="Rectangle-185" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" id="Rectangle-185-Copy" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
            </div>
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
        <div className="row ">
  <div className="col-md-7">
    <div
      style={{
        overflow: "hidden",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 10,
        width: "100%",
        maxWidth: 681,
        position: "relative"
      }}
    >
      <div style={{ width: "100%", paddingBottom: "55.50660792951542%" }} />
      <iframe
        width={681}
        height={378}
        title="unique"
        src="https://snappify.com/embed/9d440182-1b66-43aa-9f4a-e0c94278a546?responsive=1&p=1&autoplay=1&b=1"
        allow="clipboard-write"
        
        loading="lazy"
        style={{
          background: "linear-gradient(270deg,#141e30ff,#243b55ff)",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%"
        }}
        frameBorder={0}
      />
    </div>
    <div className="card detail-shadow mt-3">
      <div className="detail-head d-flex justify-content-between align-items-center py-3 px-4 flex-wrap">
        <h3 className="detail-title mb-0">
          {t("integrate")}
        </h3>
      </div>
      <div className="detail-description px-4">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil autem
          nemo repudiandae minima sed illo a. Vero, esse? Deserunt perferendis
          at similique debitis voluptatibus necessitatibus! Aut eos ullam magnam
          eius iste.
        </p>
      </div>
    </div>
  </div>
  <div className="col-md-5">
    <div className="feature-card ">
      <img src={f1} className="card-img-top" alt="f1" />
      <div className="card-body p-3 text-center">
        <h4 className="feature-card-title">
          {t("fct1")}
        </h4>
        <button className="donate-btn">
          {t("db")}
          <span>
            <i className="fa-solid fa-circle-arrow-right" />
          </span>
        </button>
    
        <div className="amount d-flex justify-content-between mt-4 flex-wrap">
          <div className="raise d-flex">
            <p className="mb-0">{t("r")}</p>
            <p className="mb-0">
              <strong>$50,000</strong>
            </p>
          </div>
          <div className="goal-amt d-flex">
            <p className="mb-0">{t("g")}</p>
            <p className="mb-0">
              <strong>$70,000</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


        </div>
      </div>
    </Modal>
  );
};

export { EmbedModal };
