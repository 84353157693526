import React, { useState } from "react";
import cardImg from "../../assets/payment-images/CardCollection.png";
import paypalImg from "../../assets/payment-images/PayPal.png";
import googlePayImg from "../../assets/payment-images/GooglePay.png";
import applePayImg from "../../assets/payment-images/ApplePay.png";
import causeImg from "../../assets/images/f2.png";
import sslImg from "../../assets/images/ssl.jpg";
import logoImg from "../../assets/images/logo.png";
import sepaImg from "../../assets/payment-images/sepa.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Donate2 = () => {
  const [selectedPayment, setSelectedPayment] = useState("card"); // Initial state for selected payment
  const [showMorePayments, setShowMorePayments] = useState(false); // State to toggle additional payment options

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.id); // Update selected payment based on radio button id
  };

  const toggleMorePayments = () => {
    setShowMorePayments(!showMorePayments); // Toggle the visibility of additional payment options
  };

  return (
    <section className="detail-section">
      <div className="container-fluid d-flex justify-content-center">
        <div className="col-12 col-md-8 col-xl-6 detail-shadow row justify-content-center donate-section-card">
          <div className="col-12 col-sm-10 col-md-10 text-center mt-3 px-2 px-md-4">
            <h3 className="detail-title mb-0">Donate Meal for Poor Kids</h3>
            <div className="detail-shadow p-3 mt-3">
              <div className="view-detail-img">
                <img src={causeImg} alt="detail-img" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10 px-2 px-md-4">
            <div className="row pt-3 pt-md-4 align-items-center">
              <div className="col-12 col-sm-6">
                <h6 className="donation-title mb-0">
                  Your Donation Amount <br />
                  will make someone’s life better.
                </h6>
              </div>
              <div className="col-12 col-sm-6">
                <input
                  type="text"
                  className="form-control bill-input d-input goal"
                  id="eventTitle"
                  placeholder="€   Your Donation"
                />
              </div>
            </div>

            <div className="pay-block row mb-3 px-3">
              <h3 className="pay-title mb-2 p-0 mt-3">Choose Payment Method</h3>
              <div className="payment-methods d-flex justify-content-start">
                <div className={`payment-card border card me-1 ${
                    selectedPayment === "card" ? "pay-select" : ""
                  }`}>
                <label
                      className="form-check-label w-100 h-100 p-3"
                      htmlFor="card"
                    >
                        <div className="payment-img">
                        <img src={cardImg} alt="card" className="img-fluid" />
                        </div>
                      <div className="pay-radio rm-radio h-100 mt-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="card"
                          checked={selectedPayment === "card"}
                          onChange={handlePaymentChange}
                        />
                        Card
                      </div>
                    </label>
                </div>
                <div className={`payment-card border card me-1 ${
                    selectedPayment === "paypal" ? "pay-select" : ""
                  }`}>
                <label
                      className="form-check-label w-100 h-100 p-3"
                      htmlFor="paypal"
                    >
                        <div className="payment-img">
                        <img src={paypalImg} alt="paypal" className="img-fluid" />
                        </div>
                      <div className="pay-radio rm-radio h-100 mt-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="paypal"
                          checked={selectedPayment === "paypal"}
                          onChange={handlePaymentChange}
                        />
                        PayPal
                      </div>
                    </label>
                </div>
                <div className={`payment-card border card me-1 ${
                    selectedPayment === "googlepay" ? "pay-select" : ""
                  }`}>
                <label
                      className="form-check-label w-100 h-100 p-3"
                      htmlFor="googlepay"
                    >
                        <div className="payment-img">
                        <img src={googlePayImg} alt="googlepay" className="img-fluid" />
                        </div>
                      <div className="pay-radio rm-radio h-100 mt-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="googlepay"
                          checked={selectedPayment === "googlepay"}
                          onChange={handlePaymentChange}
                        />
                        Google Pay
                      </div>
                    </label>
                </div>
                <div className={`payment-card border card me-1 ${
                    selectedPayment === "applepay" ? "pay-select" : ""
                  }`}>
                <label
                      className="form-check-label w-100 h-100 p-3"
                      htmlFor="applepay"
                    >
                        <div className="payment-img">
                        <img src={applePayImg} alt="applepay" className="img-fluid" />
                        </div>
                      <div className="pay-radio rm-radio h-100 mt-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="applepay"
                          checked={selectedPayment === "applepay"}
                          onChange={handlePaymentChange}
                        />
                        Apple Pay
                      </div>
                    </label>
                </div>
                <button className="card border h-100" onClick={toggleMorePayments}>
                    <div className="w-100 text-center pt-3">
                        <div className="toggle-payment-img mb-1">
                            <img src={sepaImg} alt="applepay" className="img-fluid" />
                        </div>
                        {showMorePayments ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </div>
                    
                </button>
                {/* <div
                  className={`card h-100 my-1 p-3 text-center`}
                >
                  <div className="form-check w-100">
                  <button
                    className="btn show-more-btn w-100"
                    onClick={toggleMorePayments}
                    >
                    {showMorePayments ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </button>
                  </div>
                </div> */}
              </div>

              {showMorePayments && 
                // <div className="payment-methods d-flex justify-content-start mt-2">
                //     <div className={`payment-card border card me-1 ${
                //         selectedPayment === "sepa" ? "pay-select" : ""
                //     }`}>
                //     <label
                //         className="form-check-label w-100 h-100 p-3"
                //         htmlFor="sepa"
                //         >
                //             <div className="payment-img">
                //             <img src={sepaImg} alt="sepa" className="img-fluid" />
                //             </div>
                //         <div className="pay-radio rm-radio h-100 mt-1">
                //             <input
                //             className="form-check-input"
                //             type="radio"
                //             name="flexRadioDefault"
                //             id="sepa"
                //             checked={selectedPayment === "sepa"}
                //             onChange={handlePaymentChange}
                //             />
                //             Sepa
                //         </div>
                //         </label>
                //     </div>
                // </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex flex-column">
                        <div className={`payment-card-drop border ${
                            selectedPayment === "sepa" ? "pay-select" : ""
                        }`}>
                        <label
                            className="form-check-label w-100 h-100 p-1"
                            htmlFor="sepa"
                            >
                                <div className="row">
                                   <div className="col-6 payment-img-drop">
                                        <img src={sepaImg} alt="sepa" className="img-fluid" />
                                   </div>
                                    <div className="col-6 pay-radio rm-radio h-100 mt-1">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="sepa"
                                        checked={selectedPayment === "sepa"}
                                        onChange={handlePaymentChange}
                                        />
                                        Sepa
                                    </div>
                                </div>
                                
                            
                            </label>
                        </div>
                        <div className={`payment-card-drop border ${
                            selectedPayment === "paypal" ? "pay-select" : ""
                        }`}>
                        <label
                            className="form-check-label w-100 h-100 p-1"
                            htmlFor="paypal"
                            >
                                <div className="row">
                                   <div className="col-6 payment-img-drop">
                                        <img src={paypalImg} alt="sepa" className="img-fluid" />
                                   </div>
                                    <div className="col-6 pay-radio rm-radio h-100 mt-1">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="paypal"
                                        checked={selectedPayment === "paypal"}
                                        onChange={handlePaymentChange}
                                        />
                                        PayPal
                                    </div>
                                </div>
                                
                            
                            </label>
                        </div>
                    </div>
                </div>
                
              }

            </div>

            <div className="row py-3">
              <h3 className="pay-title mb-2">Enter your details</h3>
              <div className="col-12 col-md-12">
                <input
                  type="text"
                  className="form-control bill-input"
                  id="exampleFormControlInput1"
                  placeholder="Name"
                />
              </div>
              <div className="col-12 col-md-6">
                <input
                  type="email"
                  className="form-control bill-input"
                  id="exampleFormControlInput1"
                  placeholder="Email"
                />
              </div>
              <div className="col-12 col-md-6">
                <input
                  type="text"
                  className="form-control bill-input"
                  id="exampleFormControlInput1"
                  placeholder="Mobile number"
                />
              </div>
              {selectedPayment==="sepa" && 
                <div className="col-12 col-md-12">
                    <input
                    type="text"
                    className="form-control bill-input"
                    id="exampleFormControlInput1"
                    placeholder="IBAN"
                    />
                </div>
              }
              
            </div>

            <div className="form-check py-3">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="check2"
              />
              <label className="form-check-label" htmlFor="check2">
                Don't display my name publicly on the fundraiser.
              </label>
            </div>
            <section id="model-10" className="px-0">
              <div className="toggle">
                <input type="checkbox" id="check-10" />
                <label htmlFor="check-10">
                  {" "}
                  <span className="ms-3">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </span>
                </label>
              </div>
              <a
                className="btn btn-dark w-100 my-3 mt-md-4 mb-md-3 rounded-pill"
                data-bs-toggle="modal"
                href="#donations"
                role="button"
                aria-controls="donations"
              >
                PAY
              </a>
            </section>
            <div className="foot-sec px-0 pb-3 d-flex justify-content-center flex-column align-items-center">
              <div className="ssl-img">
                <img src={sslImg} alt="ssl" />
              </div>
              <p className="copy-right donation-title">
                MYFUNDBOX 2023 all rights reserved
              </p>
              <div className="ssl-img">
                <img src={logoImg} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Donate2;
