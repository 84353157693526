import React from "react";
// import { CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import success from "../../../assets/images/success.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { encrypt } from "../../../crypto-encryption/cryptoUtils";
type Props = {
  handleClose: () => void;
  id: String;
};

const CreateFundraiserSuccess: React.FC<Props> = ({ handleClose, id }) => {
  const [t] = useTranslation("global");

  const encryptedId = encrypt(`${id}`);
  const apiKey = localStorage.getItem("apiKey");
  const encryptedApiKey = encrypt(apiKey);

  return (
    <fieldset className={`cardBlock py-3 p-sm-5  br-35`}>
      {/* <div className="d-flex justify-content-end">
        <CloseButton
          onClick={handleClose}
          variant="black" // Change color of the close button
          aria-label="Close" // Accessible label for screen readers
          disabled={false} // Set to true to disable the button
          className="mt-2"
        />
      </div> */}

      {/* <section className="detail-section py-sm-5">
        <div className="container-fluid ">
          <div className="row align-items-center pb-sm-5">
            <div className="col-md-12">
              <div className="success-content-left text-center text-sm-start">
                <div className="success-icon pb-2 pb-sm-5 ">
                  <FontAwesomeIcon icon={faCircleCheck} beatFade />
                </div>
                <div className="success-content pb-2 pb-sm-5">
                  <h4>{t("success-msg")}</h4>
                </div>
                <div className="success-buttons">
                  <Link
                    to={`/viewdetail/?id=${encryptedId}&key=${encryptedApiKey}`}
                  >
                    <button
                      onClick={() => {
                        localStorage.setItem("pageReloaded", "false");
                        handleClose();
                      }}
                      className="btn btn-dark me-3"
                    >
                      {t("sb")}
                    </button>
                  </Link>
                  <Link
                    to={`/viewdetail/?id=${encryptedId}&key=${encryptedApiKey}`}
                  >
                    <button
                      onClick={() => {
                        localStorage.setItem("pageReloaded", "false");
                        handleClose();
                      }}
                      className="btn btn-outline-dark px-4"
                    >
                      OK
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none">
              <div className="success-content-right">
                <div className="success-img">
                  <img src={success} alt="success" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="success-icon">
        <i className="fa-solid fa-circle-check" />
      </div>
      <h4 className="succcess-content text-center">
        {t("success-msg")}
      </h4>
      <h4 className="succcess-content text-center">
        {t("success-msg-2")}
      </h4>
      <div className="modal-btns d-flex justify-content-center">
        <Link to={`/viewdetail/?id=${encryptedId}&key=${encryptedApiKey}`}>
          <button
            type="button"
            onClick={() => {
              localStorage.setItem("pageReloaded", "false");
              handleClose();
            }}
            className=" btn-grad me-3 px-3 py-2"
            data-bs-dismiss="modal"
          >
            Ok{" "}
            <svg
              width={15}
              height={15}
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="9.25"
                stroke="white"
                strokeWidth="1.5"
              />
              <path
                d="M13.175 11.0303C13.4679 10.7374 13.4679 10.2626 13.175 9.96967L8.40199 5.1967C8.1091 4.90381 7.63422 4.90381 7.34133 5.1967C7.04844 5.48959 7.04844 5.96447 7.34133 6.25736L11.584 10.5L7.34133 14.7426C7.04844 15.0355 7.04844 15.5104 7.34133 15.8033C7.63422 16.0962 8.1091 16.0962 8.40199 15.8033L13.175 11.0303ZM12.6445 11.25H12.6446V9.75H12.6445V11.25Z"
                fill="white"
              />
            </svg>
          </button>
        </Link>

        <Link to={`/viewdetail/?id=${encryptedId}&key=${encryptedApiKey}`}>
          <button
            type="button"
            onClick={() => {
              localStorage.setItem("pageReloaded", "false");
              handleClose();
            }}
            className=" btn-grad px-3 py-2"
            data-bs-dismiss="modal"
          >
            {t("sb")}{" "}
            <svg
              width={15}
              height={15}
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="9.25"
                stroke="white"
                strokeWidth="1.5"
              />
              <path
                d="M13.175 11.0303C13.4679 10.7374 13.4679 10.2626 13.175 9.96967L8.40199 5.1967C8.1091 4.90381 7.63422 4.90381 7.34133 5.1967C7.04844 5.48959 7.04844 5.96447 7.34133 6.25736L11.584 10.5L7.34133 14.7426C7.04844 15.0355 7.04844 15.5104 7.34133 15.8033C7.63422 16.0962 8.1091 16.0962 8.40199 15.8033L13.175 11.0303ZM12.6445 11.25H12.6446V9.75H12.6445V11.25Z"
                fill="white"
              />
            </svg>
          </button>
        </Link>
      </div>
    </fieldset>
  );
};

export default CreateFundraiserSuccess;
