
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from 'react';

export const Password: React.FC = () => {

    const [pwd, setPwd] = useState('SYED');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [confirmPWD, setConfirmPWD] = useState('');

    const [isShow, setIsShow] = useState(false);

    return (
        <div>
            <div className="row">
                <div className="col-12 mb-2 col-md-6">
                    <label className="form-label" htmlFor="password">
                        New Password
                    </label>
                    <input
                        placeholder="New Password"
                        id="password"
                        className="form-control bill-input"
                        defaultValue="password"
                        type={isRevealPwd ? "text" : "password"}
                        value={pwd}
                        onChange={e => setPwd(e.target.value)}
                    />

                    <span onClick={() => setIsRevealPwd(prevState => !prevState)} className="toggle-password"><FontAwesomeIcon icon={isRevealPwd ? faEyeSlash : faEye} /></span>

                </div>
                <div className="col-12 mb-2 col-md-6">
                    <label className="form-label" htmlFor="password">
                        Confirm Password
                    </label>
                    <input
                       placeholder="Confirm Password"
                       id="password"
                       className="form-control bill-input"
                       defaultValue="password"
                       type={isShow ? "text" : "password"}
                       value={confirmPWD}
                       onChange={e => setConfirmPWD(e.target.value)}
                    />
                       <span onClick={() => setIsShow(prevState => !prevState)} className="toggle-password"><FontAwesomeIcon icon={isShow ? faEyeSlash : faEye} /></span>
                </div>
             
            </div>
         
        </div>
    );
}