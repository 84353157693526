import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import en from "../src/translations/en/global.json"
import es from "../src/translations/es/global.json"
import de from "../src/translations/de//global.json"
import fr from "../src/translations/fr//global.json"
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';


i18next.init({
  interpolation:{escapeValue:false},
  lng:"en",
  resources:{
    en:{
      global:en,
    },
    es:{
      global:es,
    },
    de:{
      global:de,
    },
    fr:{
      global:fr,
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </React.StrictMode>
);


