export const Billing: React.FC = () => {

    return(
        <div>
                      <div className="row">
                        <div className="col-12 col-md-6 mb-2">
                          <label
                            htmlFor="validationCustom01"
                            className="form-label"
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom01"
                            defaultValue="Lakeville Road"
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Address.
                          </div>
                        </div>
                        <div className="col-12 col-md-3 mb-2">
                          <label
                            htmlFor="validationCustom02"
                            className="form-label"
                          >
                            NR
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom02"
                            defaultValue={450}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid NR.
                          </div>
                        </div>
                        <div className="col-12 col-md-3 mb-2">
                          <label
                            htmlFor="validationCustom03"
                            className="form-label"
                          >
                            Zipcode
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom03"
                            defaultValue={11042}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Zipcode.
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                          <label
                            htmlFor="validationCustom02"
                            className="form-label"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom02"
                            defaultValue="Lake success"
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid State.
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                          <label
                            htmlFor="validationCustom03"
                            className="form-label"
                          >
                            Country
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom03"
                            defaultValue="US"
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Zipcode.
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                          <label
                            htmlFor="validationCustom02"
                            className="form-label"
                          >
                            VAT ID
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom02"
                            defaultValue="JUASO-78"
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Email.
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                          <label
                            htmlFor="validationCustom03"
                            className="form-label"
                          >
                            Tax ID
                          </label>
                          <input
                            type="text"
                            className="form-control bill-input"
                            id="validationCustom03"
                            defaultValue="812-8210401"
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Mobile Number.
                          </div>
                        </div>
                      </div>
                    </div>
    );
}