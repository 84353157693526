import mfboff from "../assets/images//mfboff.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BiPlus, BiUser } from "react-icons/bi";
import log from "../assets/profile-img/user.png";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

import { Us, De, Fr } from "react-flags-select";
import LangComp from "./LangComp";
import { useEffect, useState } from "react";
import CreateFundraiserModal from "../components/Modals/Fundraiser/CreateFundraiserModal";

const Header = () => {
  const [selected, setSelected] = useState(
    <span className="d-flex justify-content-around align-items-center">
      <Us className="me-2" /> EN
    </span>
  );
  const [selectedLang, setSelectedLang] = useState("en");
  const [show, setShow] = useState(false);
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  const apiKey = localStorage.getItem("apiKey");
  const organizationName = localStorage.getItem("organizationName");
  const firstName = localStorage.getItem("firstName");
  const languageCode = localStorage.getItem("languageCode");

  useEffect(() => {
    const fetchLanguageCode = async () => {
      if (!localStorage.getItem("selectedLang")) {
        if (languageCode === "DE") {
          setSelected(
            <span className="d-flex justify-content-around align-items-center">
              <De className="me-2" /> DE
            </span>
          );
          setSelectedLang("de");
          localStorage.setItem("selectedLang", "de");
        } else if (languageCode === "FR") {
          setSelected(
            <span className="d-flex justify-content-around align-items-center">
              <Fr className="me-2" /> FR
            </span>
          );
          setSelectedLang("fr");
          localStorage.setItem("selectedLang", "fr");
        } else if (languageCode === "EN") {
          setSelected(
            <span className="d-flex justify-content-around align-items-center">
              <Us className="me-2" /> EN
            </span>
          );
          setSelectedLang("en");
          localStorage.setItem("selectedLang", "en");
        } else {
          setSelected(
            <span className="d-flex justify-content-around align-items-center">
              <Us className="me-2" /> EN
            </span>
          );
        }
      }
    };

    fetchLanguageCode();
  }, [languageCode]);

  return (
    <>
      <Navbar
        bg="light"
        data-bs-theme="light"
        sticky="top"
        collapseOnSelect
        expand="md"
      >
        <Container className="d-flex justify-content-between align-items-center py-2">
          <Navbar.Brand>
            <img src={mfboff} alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="align-items-md-center d-sm-flex flex-sm-row  flex-column-reverse">
              <LangComp
                selected={selected}
                setSelected={setSelected}
                selectedLang={selectedLang}
              />

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => handleChangeLanguage(e.target.value)}
                className="mb-2 mb-sm-0 me-3 d-none"
              >
                <option className="px-3 " value="en">
                  {" "}
                  <span>
                    <Us /> English
                  </span>
                </option>
                {/* <option className="px-3"  value="es">Spanish</option> */}
                <option className="px-3" value="de">
                  {" "}
                  <De />
                  Deutsch
                </option>
                <option className="px-3" value="fr">
                  {" "}
                  <Fr />
                  French
                </option>
              </Form.Select>

              {apiKey && (
                <NavDropdown
                  className="dropDown"
                  title={
                    <span>
                      <img
                        src={log}
                        alt="logo"
                        style={{ width: 35 }}
                        className="me-2"
                      />
                      <BiUser className="me-2 rounded-circle d-none" />
                      Hey{" "}
                      {organizationName !== "" ? organizationName : firstName}
                    </span>
                  }
                  id="collasible-nav-dropdown"
                  drop="down-centered"
                >
                  <div className="mx-2">
                    <NavDropdown.Item
                      className="dropDownItem d-none"
                      onClick={() => setShow(true)}
                    >
                      <BiPlus className="me-2" /> {t("create-fund")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="dropDownItem"
                      onClick={() => setShow(true)}
                    >
                      {t("create-fund")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/myfundraiser"
                      className="dropDownItem"
                    >
                      {t("my-fund")}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={() => {
                        localStorage.clear();
                        window.location.href =
                          "https://fcrmrecurring.myfundbox.com/login.jsf";
                      }}
                    >
                      {t("logout")}
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <CreateFundraiserModal show={show} setShow={setShow} />
    </>
  );
};

export default Header;
