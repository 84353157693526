import { CloseButton } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCircleRight,faShareNodes } from '@fortawesome/free-solid-svg-icons';
type Props = {
    handleForm2: ()=>void;
    handleClose: ()=>void;
    handleSuccess: ()=>void;
    formData: any;
    image: any;
}

const CreateFundraiserForm3: React.FC<Props> = ({ handleForm2, handleClose , handleSuccess, formData, image }) => {
  const [t]=useTranslation("global")

  return (
    <>
    <form id="msform" className="row align-items-center  px-5">
      <div className="col-12 col-md-4 d-none ">
      <ul id='progressbar' className="text-center">
          <li className="active"> {t("create-title1")} </li>
          <li className="active">{t("create-title2")}</li>
          {/* <li>{t("create-title3")}</li> */}
          <li className="active">{t("create-title4")}</li>
        </ul>
      </div>
      <div className="col-12 col-md-12">
        <fieldset className={`cardBlock py-2 `}>
          <div className="d-flex justify-content-end">
              <CloseButton
                onClick={handleClose}
                variant="black" // Change color of the close button
                aria-label="Close" // Accessible label for screen readers
                disabled={false} // Set to true to disable the button
                className="mt-2"
              />
          </div>
          <h2 className={`fsTitle py-2`}>{t("create-title4")}</h2>
          <div
                    className={`alert alert-info d-flex donateDescription align-items-center d-md-none`}
                    role="alert"
                  >
                   <FontAwesomeIcon icon={faCircleInfo} className="me-2" />
                    <p className="mb-0">
                    For the best experience, please view this on a larger screen.
                    </p>
                  </div>
          <section className="detail-section">
            <div className="container-fluid ">
              <div className="row my-3 ">
                <div className="col-md-6 p-0">
                  <div className="view-detail-img">
                    <img src={image[0].data_url} width="100%" alt="detail-img" />
                  </div>
                </div>
                <div className="col-md-6 pt-3 p-0 p-md-2 pt-sm-0">
                  <div className="">
                    <div className="card detail-shadow mb-3">
                      <div className="venue-detail px-3 px-md-3">
                        <h5 className="venue-title">
                      {/* {t("fct2")} */}
                      {formData.prodName}
                        </h5>
                        <p className="needed-money">
                          <span className="raised">{localStorage.getItem("currencySymbol")}0</span>&nbsp; {t("raised-of")}  
                          <strong>{localStorage.getItem("currencySymbol")}{formData.targetAmount} {t("goal")}</strong>
                        </p>
                        <div className="d-flex flex-column w-100 me-3 my-4">
                        
                        <div className="d-flex align-items-center">
                          <div className="progress h-10px  w-100 bg-light-dark">
                            <div
                              className="progress-bar bg-dark"
                              role="progressbar"
                              style={{ width: "0%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            0%
                          </span>
                        </div>
                      </div>
                        <button
                          className="btn btn-dark w-100 mb-3 rounded-pill disabled"
                       
                        >
                         {t("sb")}
                          <span>
                          <FontAwesomeIcon icon={faShareNodes} className="ms-3" />
                          </span>
                        </button>

                        <button
                          className="btn btn-dark w-100 mb-3 rounded-pill"
                          disabled
                        >
                      {t("db")}
                          <span>
                          <FontAwesomeIcon icon={faCircleRight} className="ms-3" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="card detail-shadow">
                    <div className="detail-head py-3 px-4 flex-wrap">
                      <h3 className="detail-title mb-0 text-start">
                       {/* {t("fct2")} */}
                       {formData.prodName}
                      </h3>
                    </div>
                    <div className="detail-description px-4 pb-3">
                      <p className="block-ellipsis">
                        {/* Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Nihil autem nemo repudiandae minima sed illo a.
                        Vero, esse? Deserunt perferendis at similique debitis
                        voluptatibus necessitatibus!.... */}
                        {formData.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="d-flex justify-content-end"> 
          <input
            type="button"
            name="previous"
            className={`next actionButton`}
            value={t("previous")}
            onClick={handleForm2}
          />
          <input
            type="submit"
            name="submit"
            className={`submit actionButton`}
            value={t("finish")}
            onClick={handleSuccess}
          />
          </div>
        </fieldset>
      </div>
    </form>
      {/* <ShareModal show={show} handleClose={() => setShow(false)} /> */}
      </>
  );
};

export default CreateFundraiserForm3;
