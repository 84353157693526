import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { CloseButton } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

type Props = {
  handleForm1: () => void;
  handleForm3: () => void;
  handleClose: () => void;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  image: any;
  setImage: React.Dispatch<React.SetStateAction<any>>;
  imageError: String;
  setImageError: React.Dispatch<React.SetStateAction<any>>;
  prodNameError: String;
  setProdNameError: React.Dispatch<React.SetStateAction<any>>;
  urlError: String;
  setUrlError: React.Dispatch<React.SetStateAction<any>>;
  storyError: String;
  setStoryError: React.Dispatch<React.SetStateAction<any>>;
};

const CreateFundraiserForm2: React.FC<Props> = ({
  handleForm1,
  handleForm3,
  handleClose, 
  formData, 
  setFormData,
  image,
  setImage,
  imageError,
  setImageError,
  prodNameError,
  setProdNameError,
  urlError,
  setUrlError,
  storyError,
  setStoryError
}) => {
  // const maxNumber = 1; // Maximum number of images user can upload
  const [t]=useTranslation("global");
  // const [imageError, setImageError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if(name==="prodName") {
      setProdNameError(null);
    }
    if(name==="videoUrl") {
      setUrlError(null);
    }    
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setStoryError(null);
  };

  const handleChange = (imgList: ImageListType) => {
    if (imgList.length > 0) {
      const imageFile = imgList[0].file;
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        const { width, height } = img;
        const tolerance = 20;
        if (
          width >= 470 - tolerance && width <= 470 + tolerance &&
          height >= 300 - tolerance && height <= 300 + tolerance
        ) {
          setImage(imgList as never[]);
          setImageError(null);
        } else {
          setImageError(`Image dimensions should be 470x300 pixels with a tolerance of ±20 pixels.`);
        }
      };
    }
  };

  return (
    <form id="msform" className="row align-items-center  px-5">
      <div className="col-12 col-md-4 d-none">
      <ul id='progressbar' className="text-center">
          <li className="active"> {t("create-title1")} </li>
          <li className="active">{t("create-title2")}</li>
          {/* <li>{t("create-title3")}</li> */}
          <li>{t("create-title4")}</li>
        </ul>
      </div>
      <div className="col-12 col-md-12">
        <fieldset className={`cardBlock py-2 `}>
          <div className="d-flex justify-content-end">
              <CloseButton
                onClick={handleClose}
                variant="black" // Change color of the close button
                aria-label="Close" // Accessible label for screen readers
                disabled={false} // Set to true to disable the button
                className="mt-2"
              />
          </div>
          <h2 className={`fsTitle py-2`}>{t("cover")}</h2>
          {imageError && <div className="text-danger ms-1">{imageError}</div>}
          {/* ReactImageUploading component */}
          <ImageUploading
                        value={image}
                        onChange={handleChange}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper ">
                            
                            <button
                              style={isDragging ? { color: 'red' } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                              type="button"
                              className="image-drop-button d-none"
                            >
                              Click or Drop here
                            </button>
                        
                         
                         <div className="picture" {...dragProps} onClick={onImageUpload}>
                         {imageList.map((image, index) => (
                              
                                <div key={index} className="image-item">
                                  <img src={image['data_url']} alt="" width="100%" />
                               </div>
                                ))} 
                                <p className="img-size-title mb-0"> Click to upload or Drag & Drop Image with size of 470 * 300 px*</p>  
                                </div>

                            
                          

                          </div>
                        )}
                      </ImageUploading>
                      <p className="card-content text-center"><a href="https://www.iloveimg.com/resize-image" target="_blank" rel="noreferrer">Click here</a> to resize the image to size of 470 * 300 px</p>
                      {/* {imageError && <div className="text-danger ms-1">{imageError}</div>} */}
          <h2 className={`fsTitle py-2`}>{t("tell-donor")}</h2>          
          <input
            type="text"
            name="prodName"
            placeholder={t("fund-title")}
            value={formData.prodName}
            onChange={handleInputChange}
            maxLength={255}
          />
          {prodNameError && <div className="text-danger ms-1">{prodNameError}</div>}  
          <input type="text" 
            name="videoUrl" 
            placeholder={t("fund-video")}
            value={formData.videoUrl}
            onChange={handleInputChange}
            maxLength={255}
          />
          {urlError && <div className="text-danger ms-1">{urlError}</div>}  
          <textarea
            name="description"
            id="story-desc"
            placeholder={t("fund-story")}
            value={formData.description}
            onChange={handleTextAreaChange}
          ></textarea>
          {storyError && <div className="text-danger ms-1">{storyError}</div>}  
         {/* {image && ( <img src={image["dataUrl"]}/>)} */}
         <div className="d-flex justify-content-end">

          <input
            type="button"
            name="previous"
            className={`next actionButton`}
            value={t("previous")}
            onClick={handleForm1}
          />
          <input
            type="button"
            name="next"
            className={`next actionButton`}
            value={t("next")}
            onClick={handleForm3}
          />
         </div>
        </fieldset>
      </div>
    </form>
  );
};

export default CreateFundraiserForm2;
