import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function FormValidation () {


    const [pwd, setPwd] = useState('SYED');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationCustom01" >
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            required
            type="text"
            className='bill-input'
            placeholder="Full name"
            defaultValue="Muhammed Ali"
          />
          <Form.Control.Feedback type="invalid">Full Name is required..!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationCustom02">
          <Form.Label>User name</Form.Label>
          <Form.Control
            required
            type="text"
            className='bill-input'
            placeholder="User name"
            defaultValue="ali_21"
          />
        <Form.Control.Feedback type="invalid">User Name is required..!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationCustomUsername">
          <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="text"
              placeholder="aliboi@gmail.com"
              className='bill-input'
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
            E-Mail is required..!
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationCustomPass">
          <Form.Label>Password</Form.Label>
            <Form.Control
             placeholder="New Password"
             id="password"
             defaultValue="password"
             type={isRevealPwd ? "text" : "password"}
             value={pwd}
             onChange={e => setPwd(e.target.value)}
              className='bill-input'
              aria-describedby="inputGroupPrepend"
              required
            />
             <span onClick={() => setIsRevealPwd(prevState => !prevState)} className="toggle-password"><FontAwesomeIcon icon={isRevealPwd ? faEyeSlash : faEye} /></span>
            <Form.Control.Feedback type="invalid">
            Password is required..!
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationCustomPass">
          <Form.Label>Currency</Form.Label>
          <Form.Select aria-label="Default select example" className='py-2'>
      <option>Open this select menu</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
            <Form.Control.Feedback type="invalid">
            Password is required..!
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationCustomPass">
         
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationPhone" >
          <Form.Label>Phone</Form.Label>
          <Form.Control
            required
            type="text"
            className='bill-input'
            placeholder="Phone"
            defaultValue="+21 8855 2104"
          />
          <Form.Control.Feedback type="invalid">Full Name is required..!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className='mb-2' controlId="validationWebsite">
          <Form.Label>Website URL</Form.Label>
          <Form.Control
            required
            type="text"
            className='bill-input'
            placeholder="website url"
            defaultValue="https://www.myfundbox.com/"
          />
        <Form.Control.Feedback type="invalid">User Name is required..!</Form.Control.Feedback>
        </Form.Group>
      </Row>
     <div className="text-end">

      <Button className="btn btn-dark  my-3 mt-md-4 mb-md-3 rounded px-5"
     type="submit">Save</Button>
     </div>
    </Form>
  );
}

export default FormValidation ;