import { useLocation } from "react-router-dom";
import axios from "axios";
import DetailImg from "./DetailImg";
import DetailSide from "./DetailSide";
// import DocsSlider from "./DocsSlider";
import { useEffect, useState } from "react";
import { ExpiredPage } from "../ExpiredPage/ExpiredPage";
import { decrypt } from "../../crypto-encryption/cryptoUtils";
import log from '../../logger';

export const ViewDetail: React.FC = () => {
  // const decryptedId = decrypt(decodeURIComponent(id));
  const [fundraiserData, setFundraiserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const paramId = queryParams.get('id');
  const decryptedId = decrypt(paramId);
  const paramKey = queryParams.get('key');
  const decryptedApiKey = decrypt(`${paramKey}`);

  useEffect(() => {
    const fetchFundraiserData = async () => {
      const reloadPageOnce = () => {
        if (localStorage.getItem('pageReloaded')==='false') {
          localStorage.setItem('pageReloaded', 'true');
          window.location.reload();
        }
      };
  
      reloadPageOnce();
      console.log("Fundraiser ID: ", decryptedId);
      try {
        const username = 'mfb_apikey';
        const apiKey = decryptedApiKey;

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
        };
        
        if(!localStorage.getItem('organizationId')) {
          const profileResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile`,
            { headers: headers }
          );
          localStorage.setItem("organizationId",profileResponse.data.object.data[0].organization_id);
          localStorage.setItem("languageCode",profileResponse.data.object.data[0].org_languageCode);
          localStorage.setItem("currencyCode",profileResponse.data.object.data[0].org_currencyCode);
          if(profileResponse.data.data[0].org_currencyCode === "USD") {
            localStorage.setItem("currencySymbol", "$");
          } else if(profileResponse.data.data[0].org_currencyCode === "EUR") {
            localStorage.setItem("currencySymbol", "€");
          }
          if(profileResponse.data.data[0].org_languageCode==="DE") {
            localStorage.setItem("selectedLang", "de");
          }else if(profileResponse.data.data[0].org_languageCode==="FR") {
            localStorage.setItem("selectedLang", "fr");
          } else if(profileResponse.data.data[0].org_languageCode==="EN") {
            localStorage.setItem("selectedLang", "en");
          }
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/fundraiser/${decryptedId}`,
          { headers: headers }
        );
        // setFundraiserData(response.data.object.data[0]);
        console.log(response.data.object.data[0]);
        setFundraiserData(response.data.object.data[0]);
        log.info('Retrieved fundraiser details with id ', decryptedId)
      } catch (error) {
        log.error("Error fetching fundraiser details:", error);
      } finally {
          setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchFundraiserData();
  }, [decryptedId,decryptedApiKey]);

  if (loading && !fundraiserData) {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if(!fundraiserData) {
    return (
      <>
        <ExpiredPage />
      </>
    )
  }

  return (
    <>
      {fundraiserData && 
        <div className="container mt-4">
          <div className="row g-0 g-xl-5 g-xxl-8">
            <div className="col-xl-8">
              <DetailImg fundraiserData={fundraiserData} id={decryptedId} />
            </div>

            <div className="col-xl-4">
              <div className="sticky-md-top top-25">
                <DetailSide fundraiserData={fundraiserData} id={paramId} decryptedApiKey={decryptedApiKey} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              {/* {fundraiserData.documentImageUrls[0] && 
                <DocsSlider fundraiserData={fundraiserData} />
              } */}
              
            </div>
          </div>
        </div>
      }
      {/* begin::Row */}
      
      {/* end::Row */}
    </>
  );
};
