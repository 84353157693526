/* eslint-disable jsx-a11y/anchor-is-valid */

import Expired from "./Expired";

export const ExpiredPage: React.FC = () => {
  return (
    <>


       <Expired/>



     
    </>
  );
};
