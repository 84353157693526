import React ,{useState } from 'react'
import { useTranslation } from "react-i18next";

type Props = {
    copyText : string;
};

const ClipboardCopy: React.FC<Props> = ({ copyText  }) => {
    const [t]=useTranslation("global")

    const [isCopied, setIsCopied] = useState(false);
// This is the function we wrote earlier
async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (

    <>
     <div className="row align-items-center">
                             
                                <div className="col-8">
                                    <input
                                      value={copyText}  
                                        type="text"
                                        name="copy-link"
                                        id="copyLink"
                                        className="bill-input form-control py-2"
                                        placeholder="https://crowdfundingmfb/604a1878"
                               
                                     
                                    />
                                </div>
                                <div className="col-4">
                                    <button
                                       onClick={handleCopyClick}
                                        className="btn btn-dark fw-bold w-100 py-2 "
                                      
                                    >
                               {isCopied ? t("copied") : t("copy-link")}
                                    </button>
                                </div>
                            </div>

        </>
  );
}
export default ClipboardCopy