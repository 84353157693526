import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';import { useTranslation } from "react-i18next";



type Props = {
  title:string;
  img:string;
  raised:number;
  goal:number;
  percentage:number;
};

const Embeded: React.FC<Props> = ({ title,img,raised,goal,percentage}) => {
  const [t]=useTranslation("global")
  return (
    <div className="container-fluid  cus-height">
    <div className="row align-items-center justify-content-center mt-sm-4">
      <div className="col-12 col-md-3">
        <div className="feature-card h-100">
            <img src={img} alt="f1" className='card-img-top' />
          <div className="card-body p-3 text-center">
            <h4 className="feature-card-title">
              {title}
            </h4>
            <button className="btn btn-dark rounded mt-3 ">
              {t("db")}
              <span>
              <FontAwesomeIcon icon={faCircleRight} className="ms-3" />

                <i className="fa-solid fa-circle-arrow-right" />
              </span>
            </button>
            <div className="d-flex flex-column w-100 me-3 my-4">
                        
                        <div className="d-flex align-items-center">
                          <div className="progress h-10px  w-100 bg-light-dark">
                            <div
                              className="progress-bar bg-dark"
                              role="progressbar"
                              style={{ width: (percentage + "%") }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                           {percentage}%
                          </span>
                        </div>
                      </div>
            <div className="amount d-flex justify-content-between mt-4 flex-wrap">
              <div className="raise d-flex">
                <p className="mb-0">{t("r")}</p>
                <p className="mb-0">
                  <strong>${raised}</strong>
                </p>
              </div>
              <div className="goal-amt d-flex">
                <p className="mb-0">{t("g")}</p>
                <p className="mb-0">
                  <strong>${goal}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Embeded