import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'fac30d2d-4253-4e09-8bb2-1ff80d29bed7',
        enableAutoRouteTracking: true // Optionally enable route tracking
    }
});

appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

export default appInsights;
