import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { EmbedModal } from "./EmbedModal";
import { useTranslation } from "react-i18next";
import ClipboardCopy from "../Clipboard/ClipboardCopy";
import embedIcon from "../../assets//images/embed.png"
import {
    EmailIcon,
    FacebookIcon,
    PinterestIcon,
    TelegramIcon,
    TumblrIcon,
    XIcon,
    WhatsappIcon
} from "react-share";
import {
    EmailShareButton,
    FacebookShareButton,
    PinterestShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,

} from "react-share";
// import { encrypt } from "../../crypto-encryption/cryptoUtils";

type Props = {
    show: boolean;
    handleClose: () => void;
    id: any;
};

const ShareModal: React.FC<Props> = ({ show, handleClose, id }) => {
    const [shows, setShow] = useState(false);
    const [t] = useTranslation("global");
    const getBaseURL = () => {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      };
    // const apiKey = localStorage.getItem("apiKey"); 
    const encryptedApiKey = localStorage.getItem("encryptedApiKey");
    const shareUrl = `${getBaseURL()}/viewdetail/?id=${id}&key=${encryptedApiKey}`;

    return (

        <>
            <Modal
                id="kt_modal_create_app"
                tabIndex={-1}
                aria-hidden="true"
                dialogClassName="modal-dialog-centered "
                show={show}
                backdrop="static"
                onHide={handleClose}
            >
                <div className="container pt-10">
                    <div className="modal-header py-2 ">
                        {/* begin::Close */}
                        <h5 className="modal-title text-center">{t("share-tit")}</h5>
                        <div className="d-flex justify-content-end border-0">

                            <div
                                className="btn btn-icon btn-sm btn-light-primary"
                                onClick={handleClose}
                            >
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" id="Rectangle-185" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" id="Rectangle-185-Copy" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body">
                        <div className="row py-2">
                            <p className="col-3 text-center pt-3 mb-0 share-links ">
                                <FacebookShareButton
                                    url={shareUrl}
                                    className=""
                                >
                                    <FacebookIcon size={45} round />
                                </FacebookShareButton>
                                <p className="text-dark">Facebook</p>
                            </p>

                            <p className="col-3 text-center pt-3 mb-0 share-links d-none">
                                <span className="share-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>

                                </span>
                                <p className="text-dark">Instagram</p>
                            </p>
                            <p className="col-3 text-center pt-3 mb-0 share-links">
                                <TwitterShareButton url={shareUrl} className="">

                                    <XIcon size={45} round />
                                </TwitterShareButton>
                                <p className="text-dark">Twitter</p>
                            </p>
                            <p className="col-3 text-center pt-3 mb-0 share-links ">
                                <EmailShareButton url={shareUrl} >
                                    <EmailIcon size={45} round />
                                </EmailShareButton>
                                <p className="text-dark">Email</p>
                            </p>
                            <p className="col-3 text-center pt-3 mb-0 share-links ">
                                <WhatsappShareButton url={shareUrl}>
                                    <WhatsappIcon size={45} round />
                                </WhatsappShareButton>
                                <p className="text-dark">WhatsApp</p>
                            </p>
                            <a
                                href="#staticBackdrop"
                                className="col-3 text-center pt-3 mb-0 share-links "
                                onClick={() => setShow(true)}
                            >
                                <span className="share-block">
                                    <img src={embedIcon} alt="embedIcon" />
                                </span>
                                <p className="text-dark">Embeded</p>
                            </a>
                            <p className="col-3 text-center pt-3 mb-0 share-links d-none">
                                <span className="share-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM64 96v64h64V96H64zM0 336c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336zm64 16v64h64V352H64zM304 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48zm80 64H320v64h64V96zM256 304c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16v64c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V304zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z" /></svg>
                                </span>
                                <p className="text-dark">QR Scan</p>
                            </p>
                            <p className="col-3 text-center pt-3 mb-0 share-links">
                                <TelegramShareButton
                                    url={shareUrl}
                                    title={"title"}
                                    className="Demo__some-network__share-button"
                                >
                                    <TelegramIcon size={45} round />
                                </TelegramShareButton>
                                <p className="text-dark">Telegram</p>
                            </p>
                            <p className="col-3 text-center pt-3 mb-0 share-links ">
                                <PinterestShareButton
                                    url={shareUrl}
                                    media={`${shareUrl}/${"exampleImage"}`}
                                    className="Demo__some-network__share-button"
                                >
                                    <PinterestIcon size={45} round />
                                </PinterestShareButton>
                                <p className="text-dark">Printerest</p>
                            </p>
                            <p className="col-3 text-center pt-3 mb-0 share-links ">
                                <TumblrShareButton
                                    url={shareUrl}
                                    title={"title"}
                                    className="Demo__some-network__share-button"
                                >
                                    <TumblrIcon size={45} round />
                                </TumblrShareButton>
                                <p className="text-dark">Tumblr</p>
                            </p>
                        </div>
                        <div className="donate-btns position-sticky bottom-0 start-0 pt-10">

                            <ClipboardCopy copyText={shareUrl} />
                        </div>

                    </div>
                </div>
            </Modal>
            <EmbedModal show={shows} handleClose={() => setShow(false)} />
        </>
    );
};






export { ShareModal };
