/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import ProfileMain from "./ProfileMain";
import ProfileTab from "./ProfileTab";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ExpiredPage } from "../ExpiredPage/ExpiredPage";
import { decrypt } from "../../crypto-encryption/cryptoUtils";
import log from '../../logger';

export const ProfiletDashboardPage: React.FC = () => {
  const { id } = useParams();
  const decryptedId = decrypt(decodeURIComponent(id));
  const [fundraiserData, setFundraiserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchFundraiserData = async () => {
      console.log("Fundraiser ID: ", decryptedId);
      try {
        const username = 'mfb_apikey';
        const apiKey = localStorage.getItem("apiKey");
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/fundraiser/${decryptedId}`,
          { headers: headers }
        );
        // const fundraiser = response.data.object.data[0];
        setFundraiserData(response.data.object.data[0]);
        console.log(response.data.object.data[0]);
        log.info('Retrieved fundraiser with id ', decryptedId)
      } catch (error) {
        log.error("Error fetching fundraiser details:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
    }
    };

    fetchFundraiserData();
  }, [decryptedId]);

  if (loading && !fundraiserData) {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if(!fundraiserData) {
    return (
      <>
        <ExpiredPage />
      </>
    )
  }

  return (
    <>
      {fundraiserData && 
        <div className="container cus-height mt-md-4">
          <div className="row ">
            <div className="col-xl-5">
              <ProfileMain fundraiserData={fundraiserData} id={decryptedId} encryptedId={id} />
            </div>

            <div className="col-xl-7">
              <ProfileTab fundraiserData={fundraiserData} id={fundraiserData.projprod_Id} encryptedId={decodeURIComponent(id)} />
            </div>
          </div>
        </div>
      }
      
    </>
  );
};
