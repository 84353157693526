// import { toAbsoluteUrl } from "../../../../_start/helpers"
// import { CreateAppModal } from "../_modals/create-app-stepper/CreateAppModal";
import React from "react";
import s_bg from "../../assets/images/s_bg.png"
import StepCard from "./StepCard";
import { useTranslation } from "react-i18next";

// type Props = {
//     show: boolean;
//     handleClose: () => void;
//   };
const StepsSection: React.FC= () =>{
  const [t]=useTranslation("global")

  return (<>
  
  <section className="fundrising-section w-100 mt-0" style={{ backgroundImage:`url(${s_bg})`,backgroundRepeat:"no-repeat",backgroundPosition:"center center" }} >
  <div className="container-fluid px-3 px-md-5">
    <h3 className="fund-rising">
      {t("fr")}
    </h3>
   <StepCard/>
  </div>
</section>



    </>
  )
}

export default StepsSection