import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import dummyImg from "../../assets/images/f3.png";
import { encrypt } from '../../crypto-encryption/cryptoUtils';

type Fundraiser = {
  projprod_Id: number;
  cause_name: string;
  collected_price: number;
  target_price: number;
}

type Props = {
  fundraisers: Fundraiser[]
}

const FeatureCard: React.FC<Props> = ({ fundraisers }) => {
  const calculatePercentage = (raised, goal) => {
    if (raised === 0 || goal === 0) {
      return 0;
    }
    if (raised > goal) {
      return 100;
    }
    const percentage = (raised / goal) * 100;
    return Math.round(percentage); // 0 decimal places
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [t] = useTranslation("global");
  

  const [imgSrcs, setImgSrcs] = useState(
    fundraisers.map(fundraiser => ({
      id: fundraiser.projprod_Id,
      src: `${process.env.REACT_APP_AZURE_URL}/cover/cover/${fundraiser.projprod_Id}`
    }))
  );

  const handleImageError = (index) => {
    setImgSrcs(prev => {
      const newImgSrcs = [...prev];
      newImgSrcs[index].src = dummyImg;
      return newImgSrcs;
    });
  };

  return (
    <div className="row">
      {fundraisers.length<=3 && fundraisers.map((fundraiser, index) => {
          const encryptedId = encrypt(`${fundraiser.projprod_Id}`);
          return (
            <div className="col-md-4 mb-4 mb-md-0 px-2" key={fundraiser.projprod_Id}>
            <div className="feature-card h-100">
              <img
                src={imgSrcs[index].src}
                alt="detail-img"
                onError={() => handleImageError(index)}
                className=""
              />
              <div className="card-body p-3 text-center">
                <h4 className="feature-card-title text-break h-100">
                  {fundraiser.cause_name}
                </h4>
                <button className="donate-btn d-none">
                  {t("db")}
                  <span>
                    <FontAwesomeIcon icon={faCircleRight} className="ms-2 mt-1" />
                  </span>
                </button>
                <a href={`/profile-dash/${encryptedId}`} className="donate-btn">
                  {t("manage")}
                  <span>
                    <FontAwesomeIcon icon={faCircleRight} className="ms-2 mt-1" />
                  </span>
                </a>
                <div className="d-flex flex-column w-100 me-3 my-4">
                  <div className="d-flex align-items-center">
                    <div className="progress h-10px w-100 bg-light-dark">
                      <div
                        className="progress-bar bg-dark"
                        role="progressbar"
                        style={{ width: calculatePercentage(fundraiser.collected_price, fundraiser.target_price) + "%" }}
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <span className="text-muted fs-7 fw-bold ps-3">
                      {calculatePercentage(fundraiser.collected_price, fundraiser.target_price)}%
                    </span>
                  </div>
                </div>
                <div className="amount d-flex justify-content-between mt-4 flex-wrap">
                  <div className="raised d-flex">
                    <p className="mb-0">{t("r")}</p>
                    <p className="mb-0">
                      <strong>{localStorage.getItem("currencySymbol")}{fundraiser.collected_price}</strong>
                    </p>
                  </div>
                  <div className="goal-amt d-flex">
                    <p className="mb-0">{t("g")}</p>
                    <p className="mb-0">
                      <strong>{localStorage.getItem("currencySymbol")}{fundraiser.target_price}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        })}
        {fundraisers.length>3 && 
          <Slider {...settings}>
          {fundraisers.map((fundraiser, index) => {
            const encryptedId = encrypt(`${fundraiser.projprod_Id}`);
            return (
              <div className="col-md-4 mb-4 mb-md-0 px-2" key={fundraiser.projprod_Id}>
              <div className="feature-card h-100">
                <img
                  src={imgSrcs[index].src}
                  alt="detail-img"
                  onError={() => handleImageError(index)}
                  className=""
                />
                <div className="card-body p-3 text-center">
                  <h4 className="feature-card-title text-break h-auto">
                    {fundraiser.cause_name}
                  </h4>
                  <button className="donate-btn d-none">
                    {t("db")}
                    <span>
                      <FontAwesomeIcon icon={faCircleRight} className="ms-2 mt-1" />
                    </span>
                  </button>
                  <a href={`/profile-dash/${encryptedId}`} className="donate-btn">
                    {t("manage")}
                    <span>
                      <FontAwesomeIcon icon={faCircleRight} className="ms-2 mt-1" />
                    </span>
                  </a>
                  <div className="d-flex flex-column w-100 me-3 my-4">
                    <div className="d-flex align-items-center">
                      <div className="progress h-10px w-100 bg-light-dark">
                        <div
                          className="progress-bar bg-dark"
                          role="progressbar"
                          style={{ width: calculatePercentage(fundraiser.collected_price, fundraiser.target_price) + "%" }}
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                      <span className="text-muted fs-7 fw-bold ps-3">
                        {calculatePercentage(fundraiser.collected_price, fundraiser.target_price)}%
                      </span>
                    </div>
                  </div>
                  <div className="amount d-flex justify-content-between mt-4 flex-wrap">
                    <div className="raised d-flex">
                      <p className="mb-0">{t("r")}</p>
                      <p className="mb-0">
                        <strong>{localStorage.getItem("currencySymbol")}{Number(fundraiser.collected_price).toFixed(2)}</strong>
                      </p>
                    </div>
                    <div className="goal-amt d-flex">
                      <p className="mb-0">{t("g")}</p>
                      <p className="mb-0">
                        <strong>{localStorage.getItem("currencySymbol")}{Number(fundraiser.target_price).toFixed(2)}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )
          })}
        </Slider>
        }
      
    </div>
  );
}

export default FeatureCard;
