import React, { useEffect } from "react";
import classes from "../../css/Profile.module.css"
import prof from "../../assets/images/prof.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Password } from "./Password";
import { Billing } from "./Billing"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ImageUploading, { ImageListType } from "react-images-uploading";
import 'react-image-upload/dist/index.css'
import FormValidation from "./FormValidation";
import FormikValidation from "./FormikValidation";
import axios from "axios";
export const Profile: React.FC = () => {



  const [image, setImage] = React.useState([]);

  const handleChange = (
    imgList: ImageListType,
  ) => {
    // data for submit
    setImage(imgList as never[]);
  };


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('http://localhost:8080/api/user/1');
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);


  return (
    <section className={"cus-height"}>
      <div className="container-fluid px-3 px-md-5 mt-4 ">
        <div className="col-10 col-md-10 col-xl-12  ">
          <div className="col-12 col-md-12 px-4 row py-4 justify-content-center">
            <div className="col-12 col-sm-4 ">
              <div
                className={`${classes.detail_shadow} py-3 d-flex justify-content-around align-items-center flex-wrap`}
              >
                <div className={`${classes.avatar_upload}`}>
                  <div className="text-center">
                    <h4 className="fw-bold mb-0 pb-2">Muhammed Ali</h4>
                    <p className="fw-bold mb-0 pb-2">mailto@li</p>
                  </div>

                  {/* ReactImageUploading component */}
                  <ImageUploading
                    value={image}
                    onChange={handleChange}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper ">


                       <div className="picture-container position-relative ">

                        <div className="profile-picture" {...dragProps} onClick={onImageUpload}>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image['data_url']} alt="" width="100%" />

                            </div>
                          ))}
                          <p className="img-size-title mb-0"><img src={prof} alt="profile-img" /></p>

                        </div>
                        <button onClick={onImageRemoveAll} type="button" className="btn btn-light fw-bold  text-center mb-0 rounded-pill profile-del-btn " ><FontAwesomeIcon icon={faTrashCan} /></button>
                        <button
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                          type="button"
                          className="btn btn-dark fw-bold  text-center mb-0 rounded  px-2 mt-2"
                        >
                          Click or Drop here
                        </button>
                       </div>

                      </div>
                      
                    )}
                  </ImageUploading>
                </div>
                <div
                  className="alert alert-primary mt-3 mx-4 p-2 text-center"
                  role="alert"
                >
                  Upload a new avatar with the size of 400px * 400px. <br />
                  Maximum upload size is <strong>1 MB</strong>.
                </div>
                <div className="text-center px-2 fw-semibold">
                  Joined in 29 Sepetember 2023
                </div>
                <div className="profile-img d-none">
                  <img src={prof} alt="profile-img" />
                </div>
                <div className="btns d-none">
                  <div className="input-group pt-2 pt-md-0">
                    <label
                      className="btn btn-dark fw-bold w-100 py-2 d-inline-block text-center mb-0 rounded mb-3"
                      htmlFor="inputGroupFile01"
                    >
                      Upload
                    </label>
                    <input
                      type="file"
                      className="form-control d-none"
                      id="inputGroupFile01"
                    />
                  </div>
                  <button
                    className="btn btn-outline-danger fw-bold w-100 py-2 d-inline-block text-center mb-0 rounded"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-8  ">
              <div className={`${classes.detail_shadow} p-4 pt-3`}>
                <h4 className="fw-bold mb-0 pb-2">Profile Details</h4>
                {/* <form className="row needs-validation"> */}


                  <Tabs>

                    <TabList className={"d-flex ps-0"} >
                      <Tab>Contact information</Tab>
                      <Tab>Password</Tab>
                      <Tab>Billing Information</Tab>
                    </TabList>


                    <TabPanel>
                      {/* <Contact /> */}
                      <FormValidation />
                    </TabPanel>
                    <TabPanel>
                      <Password />
                    </TabPanel>
                    <TabPanel>
                      <Billing />
                    </TabPanel>

                  </Tabs>
                  <div className="col-12 text-end d-none">
                    <button
                      className="btn btn-dark  my-3 mt-md-4 mb-md-3 rounded px-5"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                {/* </form> */}
                
                <FormikValidation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
