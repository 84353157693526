import React from "react";
import { Modal } from "react-bootstrap";
import  PaymentMethod  from "../PaymentCard/PaymentMethod"
import gpay from "../../assets/payment-logos/(1).png"
import card from "../../assets/payment-logos/atm-card.png"
import sepa from "../../assets/payment-logos/(12).png"
import ban from "../../assets/payment-logos/(13).png"
import eps from "../../assets/payment-logos/(17).png"
import giro from "../../assets/payment-logos/(19).png"
import ideal from "../../assets/payment-logos/(20).png"
import klarna from "../../assets/payment-logos/(25).png"
import apple from "../../assets/payment-logos/(33).png"
import link from "../../assets/payment-logos/(41).png"
import { useTranslation } from "react-i18next";
import {  Slide,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  show: boolean;
  handleClose: () => void;

};


const PaymentConnectModal: React.FC<Props> = ({ show, handleClose  }) => {

const handleClick = () => {
notify()
handleClose();
};


  const [t]=useTranslation("global")
  const notify = () => toast.success(t("updated"), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Slide,
    });
  const Payments = [

    {
        id: 1,
        img: gpay,
        title: "Google-Pay",
        valid: false
    },
    {
        id: 2,
        img: card,
        title: "Card",
        valid: false
    },
    {
        id: 3,
        img: sepa,
        title: "Sepa",
        valid: false
    },
    {
        id: 4,
        img: ban,
        title: "Bancontact",
        valid: false
    },
    {
        id: 5,
        img: eps,
        title: "EPS",
        valid: false
    },
    {
        id: 6,
        img: giro,
        title: "GiroPay",
        valid: false
    },
    {
        id: 7,
        img: ideal,
        title: "Ideal",
        valid: false
    },
    {
        id: 8,
        img: klarna,
        title: "Klarna",
        valid: false
    },
    {
        id: 9,
        img: apple,
        title: "ApplePay",
        valid: false
    },
    {
        id: 10,
        img: link,
        title: "Link",
        valid: false
    },
]
  

 


  return (

    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog-centered modal-lg h-auto"
        show={show}
        backdrop="static"
        onHide={handleClose}
      >
        <div className="container pt-10">
          <div className="modal-header py-2 ">
            {/* begin::Close */}
            <h5 className="modal-title text-center">{t("connected")}</h5>
            <div className="d-flex justify-content-end border-0">

              <div
                className="btn btn-icon btn-sm btn-light-primary"
                onClick={handleClose}
              >
                 <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" id="Rectangle-185" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" id="Rectangle-185-Copy" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
              </div>
            </div>
            {/* end::Close */}
          </div>

          <div className="modal-body  pt-5 pb-3">
            <div className="moreInfo d-none">
              <div className="mb-3 row">
                <label htmlFor="clAcc" className="col-3 col-form-label">
                  Client Account ID*
                </label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    id="clAcc"
                    placeholder="acct_19u*************"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="clSec" className="col-3 col-form-label">
                  Client Secret API*
                </label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    id="clSec"
                    placeholder="sk_test_************************"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="clPub" className="col-3 col-form-label">
                  Client Publishable API*
                </label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    id="clPub"
                    placeholder="pk_test_************************"
                  />
                </div>
              </div>
            </div>
            <div className="payment-methods row  align-items-center g-4">
              {Payments.map((pay)=>(

              <PaymentMethod img={pay.img} valid={pay.valid} id={pay.id} title={pay.title} />
              ))}
            </div>

            <div className=" position-sticky bottom-0 start-0 p-3">
                        <div className="d-flex justify-content-end ">
                            <button className="btn px-4 btn-dark me-3" type="button" onClick={handleClick} >
                              {t("update")}
                            </button>
                            
                         
                            <button
                                className="btn px-4 btn-outline-dark"
                                data-bs-dismiss="modal"
                                type="button"
                            >
                              {t("cancel")}
                            </button>
                        </div>
                    </div>
          </div>
          
        </div>

      </Modal>
   
    </>
  );
};

export { PaymentConnectModal };
 

