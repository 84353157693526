import Slider from "react-slick";
import "react-medium-image-zoom/dist/styles.css";
import { useTranslation } from "react-i18next";
// import AzureStorageService from "../../azure/AzureStorage";
import Zoom from 'react-medium-image-zoom';

const DocsSlider = ({ id, documentUrls }) => {
  const [t] = useTranslation("global");

  // var settings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     autoplay:true,
  //     slidesToShow: 4,
  //     slidesToScroll: 1
  // };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const documentUrlss = "https://mfbcrowdfunding.blob.core.windows.net/cover/cover/7694/598a6839-39d5-4106-9572-3394af754662,https://mfbcrowdfunding.blob.core.windows.net/cover/cover/7694/3495c46a-d637-401a-8465-17d55f94bfc9,https://mfbcrowdfunding.blob.core.windows.net/cover/cover/7694/85637580-c018-4b31-9256-a9e50283c644";
  if(documentUrls) {
    var Slide = documentUrls.split(",");
  }
  
  // console.log(Slide);

  // const azureStorageService = new AzureStorageService();
  // azureStorageService.getBlobUrlsForIdTest(id);
  // azureStorageService.getImage(id);
  // const Slide = azureStorageService.getBlobUrlsForId(id);
  // Slide.then(response => {
  //   if(response.ok) {
  //     return response.json()
  //   } else {
  //     // Handle error
  //     throw new Error('Network response was not ok.');
  //   }
  // }).then(data => {
  //   console.log(data.data);
  // })
  // .catch(error => {
  //   // Handle error
  //   console.error('Error:', error);
  // });
  // const Slide = [
  //     {
  //         "title": "Supply Clean Drinking Water to Everyone",
  //         "image": f1,
  //     },
  //     {
  //         "title": "Donate Meal for Poor Kids",
  //         "image": f2,
  //     },
  //     {
  //         "title": "Ensure Child Education in Local Area",
  //         "image": f3,
  //     },
  //     {
  //         "title": "Ensure Child Education in Local Area",
  //         "image": doc1,
  //     },
  //     {
  //         "title": "Ensure Child Education in Local Area",
  //         "image": doc2,
  //     },

  // ]

  if(!documentUrls) {
    return;
  }

  return (
    <div className="card detail-shadow mt-4">
      <div className="detail-head d-flex justify-content-between align-items-center py-3 px-4 flex-wrap">
        <h3 className="detail-title mb-0">{t("documents")}</h3>
      </div>

      <div className="donation-img row mx-3 my-1 ">
        {Slide.length<=3 && Slide.map((e)=>(
            <div className="col-3 px-1">
            <Zoom>
              <img src={e} alt="document" />
            </Zoom>
            </div>
          ))}

        {Slide.length>3 && (
          <Slider {...settings}>
            {Slide.map((e)=>(
              <div className="col-3 px-1">
              <Zoom>
                <img src={e} alt="document" />
              </Zoom>
              </div>
            ))}
          </Slider>
        )}

        
      </div>
    </div>
  );
};

export default DocsSlider;
