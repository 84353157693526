import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import dummyImg from "../../assets/images/f3.png";
import { encrypt } from '../../crypto-encryption/cryptoUtils';

type Props = {
  title:string;
  id:number;
  raised:number;
  goal:number;
  percentage:number;
};

const DeletedPost: React.FC<Props> = ({ title,id,raised,goal,percentage}) => {

  const [imgSrc, setImgSrc] = useState(`${process.env.REACT_APP_AZURE_URL}/cover/cover/${id}`);

  const encryptedId = encrypt(`${id}`);

  return (

      <div className="col-12 col-sm-6 col-md-4 my-2 deleted-post">
        <div className="feature-card h-100">
            <img src={imgSrc} onError={()=>setImgSrc(dummyImg)} alt={title}  />
          <div className="card-body p-3 text-center">
            <h4 className="feature-card-title text-break h-100">
              {title}
            </h4>
            <a href={`/profile-dash/${encryptedId}`} className="btn btn-dark rounded mt-1  d-none">
              Manage
              <span>
              <FontAwesomeIcon icon={faCircleRight} className="ms-3" />

                <i className="fa-solid fa-circle-arrow-right" />
              </span>
            </a>
            <div className="d-flex flex-column w-100 me-3 my-4">
                        
                        <div className="d-flex align-items-center">
                          <div className="progress h-10px  w-100 bg-light-dark">
                            <div
                              className="progress-bar bg-dark"
                              role="progressbar"
                              style={{ width: (percentage + "%") }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            {Math.round(percentage)}%
                          </span>
                        </div>
                      </div>
            <div className="amount d-flex justify-content-between mt-4 flex-wrap">
              <div className="raise d-flex">
                <p className="mb-0">Raised:</p>
                <p className="mb-0">
                  <strong>{localStorage.getItem("currencySymbol")}{Number(raised).toFixed(2)}</strong>
                </p>
              </div>
              <div className="goal-amt d-flex">
                <p className="mb-0">Goal:</p>
                <p className="mb-0">
                  <strong>{localStorage.getItem("currencySymbol")}{Number(goal).toFixed(2)}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

  
  )
}

export default DeletedPost