import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
    show: boolean;
    handleClose: () => void;
};

const OfflineDonationModal: React.FC<Props> = ({ show, handleClose }) => {
    const [t]=useTranslation("global")

    return (

        <>
            <Modal
                id="kt_modal_create_app"
                tabIndex={-1}
                aria-hidden="true"
                dialogClassName="modal-dialog-centered  w-800px"
                show={show}
                backdrop="static"
                onHide={handleClose}
            >
                <div className="container pt-10">
                    <div className="modal-header py-2 ">
                        {/* begin::Close */}
                        <h5 className="modal-title text-center">{t("offline-don")}</h5>
                        <div className="d-flex justify-content-end border-0">

                            <div
                                className="btn btn-icon btn-sm btn-light-primary"
                                onClick={handleClose}
                            >
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" id="Rectangle-185" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" id="Rectangle-185-Copy" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body pb-0 ">
                        <div className="row ">
                            <div className="col-12 pb-0 text-center px-2">
                                <p className="don-content">

                                    {t("don-content")}
                                </p>
                                <h4 className="don-form-title text-start fs-6">{t("don-details")}</h4>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <input
                                            type="text"
                                            className="form-control bill-input rounded-0 m-0"
                                            placeholder={t("donor-name")}
                                        />
                                    </div>
                                    <div className="col-sm-6 ">
                                        <input
                                            type="text"
                                            className="form-control bill-input rounded-0 m-0"
                                            placeholder={t("don-amount")}
                                        />
                                    </div>
                                </div>
                                <div className="form-check text-start my-3">
                                    <input
                                        className="form-check-input "
                                        type="checkbox"
                                        defaultValue=""
                                        id="check1"
                                    />
                                    <label className="form-check-label" htmlFor="check1">
                                        {t("display")}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" position-sticky bottom-0 start-0 p-3">
                        <div className="d-flex justify-content-center">
                            <button className="btn px-4 btn-dark me-3" type="button">
                               {t("add-donation")}
                            </button>
                            <button
                                className="btn px-4 btn-outline-dark"
                                data-bs-dismiss="modal"
                                type="button"
                            >
                              {t("cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export { OfflineDonationModal };
