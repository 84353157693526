import React from 'react'
import { useTranslation } from 'react-i18next'
const Footer = () => {
    const [t]=useTranslation("global")

    return (

        <>
            <div className="container footer py-4 d-flex flex-lg-column justify-content-center bg-light"  >
                {/* begin::Container */}
                <div
                    className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    {/* begin::Copyright */}
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-bold me-2">
                            {new Date().getFullYear()} &copy;
                        </span>
                        <span className="text-dark">
                        {t("footer")}
                        </span>
                    </div>
                    {/* end::Copyright */}

                    {/* begin::Nav */}
                    <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                        <li className="menu-item">
                            <a href="/" className="menu-link ps-0 pe-2">
                           {t("abt")}
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="/" className="menu-link pe-0 pe-2">
                            {t("con")}
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="/" className="menu-link pe-0">
                            {t("pur")}
                            </a>
                        </li>
                    </ul>
                    {/* end::Nav */}
                </div>
                {/* end::Container */}
            </div>

            <section className="footer-section d-none">
                <footer>
                    <div className="container">

                        <div className="copyrights text-center pt-3">
                            <p className="text-dark">©MYFUNDBOX {new Date().getFullYear()} all rights reserved</p>
                        </div>

                    </div>
                </footer>
            </section>
        </>
            )
}

export default Footer