/* eslint-disable jsx-a11y/anchor-is-valid */

import Embeded from "./Embeded";
import f1 from "../../assets/images/f1.png"
import { useTranslation } from "react-i18next";


export const EmbedPage: React.FC = () => {
  const [t]=useTranslation("global")
  return (
    <>

      {/* begin::Row */}
     
       <Embeded title={t("fct1")} img={f1} raised={50000} goal={80000} percentage={80}/>

  
      {/* end::Row */}


     
    </>
  );
};
