import CardPost from "./CardPost";
import DeletedPost from "./DeletedPost";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useEffect, useState } from "react";
import axios from "axios";
import log from '../../logger';
import { useTranslation } from "react-i18next";

const MyFundraisers = () => {
  const [t]=useTranslation("global")
  // const handleChangeLanguage=(lang:string) =>{
  //   i18n.changeLanguage(lang)
  // }
  // const Fundraisers = [
  //   {
  //     Id: 1,
  //     title: "Supply Clean Drinking Water to Everyone",
  //     img: f1,
  //     raised: 50000,
  //     goal: 80000,
  //     percentage: 80,
  //   },
  //   {
  //     Id: 2,
  //     title: "Donate Meal for Poor Kids",
  //     img: f2,
  //     raised: 80000,
  //     goal: 200000,
  //     percentage: 45,
  //   },
  //   {
  //     Id: 3,
  //     title: "Ensure Child Education in Local Area",
  //     img: f3,
  //     raised: 60000,
  //     goal: 100000,
  //     percentage: 60,
  //   },
  //   // {
  //   //     Id:4,
  //   //     title:"Supply Clean Drinking Water to Everyone",
  //   //     img:drawing,
  //   //     raised:45000,
  //   //     goal:200000,
  //   //     percentage:24
  //   // },
  // ];

  const [fundraisers, setFundraisers] = useState(null);
  const [deletedFundraisers, setDeletedFundraisers] = useState(null);
  const organizationId = localStorage.getItem("organizationId");;

  const calculatePercentage = (raised, goal) => {
    if(raised===0 || goal===0) {
      return 0;
    }
    if(raised>goal) {
      return 100;
    }
    const percentage = (raised / goal) * 100;
    return percentage;
  }

  useEffect(() => {
    const fetchFundraisersData = async () => {
      try {
        const username = 'mfb_apikey';
        const apiKey = localStorage.getItem("apiKey");
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/fundraiser/organization/${organizationId}?limit=100`,
          { headers: headers }
        );
        setFundraisers(response.data.object.data);
        console.log(response.data.object.data);
        log.info('Retrieved fundraisers for organization id - ', organizationId);

        const response2 = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/fundraiser/organization/${organizationId}?deleted_list=true&&limit=100`,
          { headers: headers }
        );
        setDeletedFundraisers(response2.data.object.data);
        console.log(response2.data.object.data);
        log.info('Retrieved deleted fundraisers for organization id - ', organizationId);
      } catch (error) {
        log.error("Error fetching fundraiser details:", error);
      }
    };

    fetchFundraisersData();
  }, [organizationId]);

  return (
    <div className="container cus-height ">
      <h1 className="fund-rising py-4">  {t("my-fund")}</h1>

      <Tabs>
        <TabList className={"d-flex fund-panel"}>
          <Tab classID="fund-tab">
          {t("published")}
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
              {fundraisers && fundraisers.length}
            </span>
          </Tab>
          <Tab classID="fund-tab">
          {t("deleted")}
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {deletedFundraisers && deletedFundraisers.length}
            </span>
          </Tab>
        </TabList>

        <TabPanel>
          {fundraisers && (
            <div className="row mt-sm-4 px-3 px-md-0">
              {fundraisers.map((fund) => (
                <CardPost
                  title={fund.cause_name}
                  id={fund.projprod_Id}
                  raised={fund.collected_price}
                  goal={fund.target_price}
                  percentage={calculatePercentage(fund.collected_price,fund.target_price)}
                />
              ))}
            </div>
          )}
        </TabPanel>
        <TabPanel>
          {deletedFundraisers && (
            <div className="row mt-sm-4 px-3 px-md-0">
              {deletedFundraisers.map((fund) => (
                <DeletedPost
                  title={fund.cause_name}
                  id={fund.projprod_Id}
                  raised={fund.collected_price}
                  goal={fund.target_price}
                  percentage={calculatePercentage(fund.collected_price,fund.target_price)}
                />
              ))}
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MyFundraisers;
