/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import HeroSection from "./HeroSection";
// import { CreateAppModal } from "../_modals/create-app-stepper/CreateAppModal";
import StepsSection from "./StepsSection";
import FeatureSection from "./FeatureSection";
import CreateFundraiserModal from "../Modals/Fundraiser/CreateFundraiserModal";

export const StartDashboardPage: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {/* begin::Row */}
    
      <div className="container ">
      <HeroSection openModal={() => setShow(true)}/>
      <StepsSection  />
      {/* <WhomSection/> */}
      <FeatureSection/>
   
   <CreateFundraiserModal show={show} setShow={setShow}/>
      </div>
      {/* end::Row */}




    

      {/* begin::Modals */}
      {/* <CreateAppModal show={show} handleClose={() => setShow(false)} /> */}
      {/* end::Modals */}
    </>
  );
};
