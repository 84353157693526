import log from 'loglevel';
import appInsights from './appInsights';

// Map log levels to Application Insights severity levels
const severityLevelMap = {
    trace: 0, // Verbose
    debug: 1, // Verbose
    info: 1,  // Information
    warn: 3,  // Warning
    error: 4  // Error
};

// Original methodFactory to use later
const originalFactory = log.methodFactory;

// Custom methodFactory
log.methodFactory = function(methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);
    return function(...messages) {
        const severityLevel = severityLevelMap[methodName] || 2; // Default to Information if undefined
        if (process.env.NODE_ENV === 'production') {
            // Send logs to Application Insights in production
            appInsights.trackTrace({ message: messages.join(' '), severityLevel });
        } else {
            // Log to console in non-production environments
            rawMethod(...messages);
        }
    };
};

// Set the default log level. This can be adjusted based on your environment.
if (process.env.NODE_ENV === 'production') {
    log.setLevel('debug');  // Only log errors in production
} else {
    log.setLevel('debug');  // Log everything in development
}

export default log;
