import React, { useState } from "react";



type Props = {
    valid: boolean;
    id: number;
    img: string;
    title:string;
};
const PaymentMethod: React.FC<Props> = ({ valid, id ,img ,title}) => {

    const [active, setActive] = useState(valid);


    
    return (

        <div className="col-3 col-sm-2 col-md-2 mb-4 px-4 d-flex justify-content-center"  key={id}>
            <div className={`pay-card position-relative ${active ? "active" :""}`}
                onClick={() => setActive((prevState) => !prevState)}
            >
                <div className="card-block">
                    <img src={img} alt={title} />

                </div>
                <div className="ribbon up">
                    <div className="content">
                        <svg
                            width="16px"
                            height="16px"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="check"
                            className="svg-inline--fa fa-check fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default PaymentMethod