// import { toAbsoluteUrl } from "../../../../_start/helpers"
import { useState } from "react";
import DocsSlider from "./DocsSlider"
// import { useTranslation } from "react-i18next";
import dummyImg from "../../assets/images/f3.png";

type Props = {
  fundraiserData: any;
  id: any;
}

const DetailImg: React.FC<Props> = ({ fundraiserData, id }) => {
  // const [t]=useTranslation("global")

  const [imgSrc, setImgSrc] = useState(`${process.env.REACT_APP_AZURE_URL}/cover/cover/${id}`);

  return (
<>
  <div className="view-detail-img mb-3">
    <img src={imgSrc} alt="detail-img" onError={()=>setImgSrc(dummyImg)} />
  </div>
  <div className="card detail-shadow p-3 ">
    <div className="detail-head d-flex justify-content-between align-items-center mb-3 px-6 flex-wrap">
      <h3 className="detail-title mb-0 text-break">
        {/* {t("fct2")} */}
        {fundraiserData.cause_name}
      </h3>
    </div>
    <div className="detail-description px-6">
      <p>
        {fundraiserData.cause_description}
      </p>
    </div>
  </div>
  <DocsSlider id={id} documentUrls={fundraiserData.documents_url} />
</>

  )
}

export default DetailImg